<template>
    <div class="tw-relative tw-flex tw-items-center tw-w-full">
        <textarea 
            v-bind="$attrs"
            :id="props.id"
            :value="props.modelValue"
            @input="emit('update:modelValue', $event.target.value)"
            :required="props.required"
            :disabled="props.disabled"

            :class="[
                'tw-peer tw-w-full tw-min-h-[40px] tw-outline-none tw-py-4 tw-text-sm tw-rounded-md tw-border tw-border-solid focus:tw-border-primary-500 tw-px-4 tw-duration-200',
                props.invalid && '!tw-border-red-500',
                !!props.leftIcon && 'tw-pl-12',
                !!props.rightIcon && 'tw-pr-12',
            ]"
        ></textarea>
        <icon 
            v-if="!!props.leftIcon"
            :icon="props.leftIcon" 
            :class="[
                'tw-absolute tw-pointer-events-none peer-focus:tw-text-primary-500 tw-duration-200 tw-left-4 tw-text-xl tw-text-gray-500',
                props.invalid && '!tw-text-red-500'
            ]"
            />
        <icon 
            v-if="!!props.rightIcon"
            :icon="props.rightIcon" 
            :class="[
                'tw-absolute tw-pointer-events-none peer-focus:tw-text-primary-500 tw-duration-200 tw-right-4 tw-text-xl tw-text-gray-500',
                props.invalid && '!tw-text-red-500'
            ]" 
            />
    </div>

</template>

<script setup>
import { defineProps, defineEmits } from 'vue';

const props = defineProps({
    id: String,
    modelValue: [String, Number],
    required: Boolean,
    invalid: Boolean,
    disabled: Boolean,
    ariaDescribedBy: String,
    leftIcon: String,
    rightIcon: String,
});

const emit = defineEmits(['update:modelValue'])

</script>

<style>
    
</style>