import { getFromLocalStorage, saveInLocalStorage } from '@/utils/local-storage';
import { defineStore } from 'pinia';

const initialState = {
    isLoggedIn: getFromLocalStorage('isLoggedIn', false),
    user: getFromLocalStorage('user', null),
    token: getFromLocalStorage('token', null),
    sessionChecked: false
}

export const useAuthStore = defineStore('authStore', {
    state: () => initialState,

    getters: {},

    actions: {
        setIsLoggedIn(value) {
            this.isLoggedIn = value;
            saveInLocalStorage('isLoggedIn', value);
        },

        setToken(value) {
            this.token = value;
            saveInLocalStorage('token', value);
        },

        setRoles(value) {
            this.roles = value;
            saveInLocalStorage('roles', value);
        },

        setUser(value, isLoggedIn = null) {
            this.user = value;
            saveInLocalStorage('user', value);

            if(isLoggedIn != null) 
                this.setIsLoggedIn(isLoggedIn);
        },

        logout() {
            this.setIsLoggedIn(false);
            this.setRoles([]);
            this.setUser(null);
            this.setToken(null);
        },

        setSessionCheck(value) {
            this.sessionChecked = value
        }
    }
});