<template>
  <div class="tw-p-2 tw-bg-white tw-w-full tw-border tw-border-solid tw-flex tw-items-center tw-gap-2">
      <router-link :to="{ name: 'pages.dashboard.admin' }" class="tw-text-gray-400 tw-duration-200 hover:tw-text-gray-700 tw-text-xl tw-flex tw-items-center tw-gap-2"><icon icon="solar:home-smile-bold-duotone" /> <span class="tw-text-sm">{{ $t("dashboard.sidebar.dashboard") }}</span></router-link>
      <span class="tw-text-gray-300">/</span>
      <button disabled class="tw-text-primary-500 tw-text-xl tw-flex tw-items-center tw-gap-2"><span class="tw-text-sm">{{ $t("dashboard.sidebar.products") }}</span></button>
  </div>
  <div class="tw-p-2">
      <div class="tw-h-full tw-w-full tw-p-4 tw-bg-white tw-rounded tw-border tw-border-b-2 tw-min-h-[calc(100dvh-125px)]">
          <div class="tw-justify-between tw-flex tw-items-center tw-h-[40px] tw-gap-2">
            <FormField
                v-model="search"
                input-dir="ltr"
                placeholder="Product ID, Name..."
                left-icon="clarity:search-line"
                type="text"
                class="tw-min-w-[100px]"
              />
              <a-button class="tw-hidden md:tw-flex" as="router-link" :to="{ name: 'pages.admin.products.create' }" left-icon="heroicons:plus-16-solid">{{ $t("buttons.new-product") }}</a-button>
              <a-button class="md:tw-hidden" as="router-link" :to="{ name: 'pages.admin.products.create' }" icon="heroicons:plus-16-solid"></a-button>
          </div>
          <div class="tw-mt-5 tw-roundedx tw-overflow-hiddenx">
            <ProductTable :loading="loading" :items="products" />
          </div>
      </div>

  </div>
</template>

<script setup>
import { ref } from 'vue'
import { paginate } from '@/api/products';
import FormField from "@/components/form/FormField";
import ProductTable from '@/views/admin/products/partials/table/ProductTable'

const search = ref('')
const loading = ref(true)
const products = ref([]);

async function fetchProducts() {
  loading.value = true;
  await paginate({ per_page: 10 })
  .then(
    ({ data }) => {
      products.value = data.data.data;
      console.log(products.value)
    },
    error => {
      console.log(error)
    }
  )
  loading.value = false

  console.log()
}
products
fetchProducts()
</script>

<style>

</style>