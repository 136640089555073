<template>
    <div class="tw-cursor-pointer tw-flex tw-h-full tw-items-center tw-justify-center">
        <icon icon="iconamoon:arrow-down-2-light" class="tw-text-3xl tw-text-gray-400 group-[.tw-border-red-500]:tw-text-red-500" />
    </div>
</template>

<script setup>
</script>

<style>

</style>