import { FRONTEND_URL, BACKEND_URL } from "@/config/app";
import { useAuthStore } from "@/stores";

export default {
    install(app) {
        const auth = useAuthStore();

        app.config.globalProperties.$frontend = (path) => FRONTEND_URL + path;
        app.config.globalProperties.$backend = (path) => BACKEND_URL + path;
        console.log('Global Properties Installed Successfully ✅')

        const handler = {
            get: (target, key) => {

                return auth.user[key];
            }
        }
        
        app.config.globalProperties.$user = new Proxy(auth.user ? auth.user : {}, handler);

    }
}   