<template>
    <div>
        <FormLabel 
            v-if="!props.hideLabel"
            :for="props.id" 
            :required="props.required"
            >{{ props.label }}</FormLabel>
            <div class="tw-relative">
                <vue-select
                    v-bind="$attrs"
                    v-model="value"
                    :style="cssVars"
                    :clearable="props.clearable"
                    :searchable="props.searchable"
                    :options="props.options"
                    :label="props.optionLabel"
                    :reduce="option => option[props.optionValue]"
                    :components="{ OpenIndicator: FormVueSelectIndicator }"
                    :dir="$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
                    class="tw-border tw-border-solid tw-group tw-border-gray-200 tw-duration-200 focus-within:tw-border-primary-500 tw-outline-none tw-rounded-md tw-h-[40px]"
                    :class="[
                        !!props.error && '!tw-border-red-500',
                    ]"
                    ref="vselect">
                    <template v-slot:no-options>
                        <p class="tw-p-2 tw-text-center tw-text-sm tw-text-gray-500" >{{ $t('messages.table-no-results') }}</p>
                    </template>
                    <template v-if="$slots.option" v-slot:option="option">
                        <slot name="option" :option="option" />
                    </template>

                    <template v-if="$slots.selected || true" v-slot:selected-option="option">
                        <slot name="selected" :option="option" />
                    </template>
                </vue-select>

                <icon 
                    v-if="!!props.leftIcon"
                    :icon="props.leftIcon"
                    :class="[
                        'tw-absolute tw-pointer-events-none peer-focus:tw-text-primary-500 tw-duration-200 tw-left-4 tw-top-1/2 -tw-translate-y-1/2 tw-text-xl tw-text-gray-500',
                        !!props.error && '!tw-text-red-500',
                        $root.$i18n.locale == 'ar' && '!tw-right-4'
                    ]"
                    />
                <icon 
                    v-if="!!props.rightIcon"
                    :icon="props.rightIcon" 
                    :class="[
                        'tw-absolute tw-pointer-events-none peer-focus:tw-text-primary-500 tw-duration-200 tw-right-4 tw-top-1/2 -tw-translate-y-1/2 tw-text-xl tw-text-gray-500',
                        !!props.error && '!tw-text-red-500'
                    ]" 
                    />
            </div>


        <FormErrorMessage v-if="!props.hideErrors"
            
            >{{ props.error }}</FormErrorMessage>

        <FormHelperMessage 
        :id="ariaDescribedBy"
        v-if="!!props.help"
        >{{ props.help }}</FormHelperMessage>
    </div>
</template>

<script setup>
import VueSelect from "vue-select";
import { ref, onMounted } from "vue";
import FormVueSelectIndicator from "./FormVueSelectIndicator";
import FormErrorMessage from './FormErrorMessage.vue';
import FormHelperMessage from './FormHelperMessage.vue';

import FormLabel from './FormLabel.vue';
import { defineEmits, defineProps, computed } from 'vue';
import { v4 as uuid } from 'uuid'
import { useI18n } from "vue-i18n";

const emit = defineEmits(['update:modelValue'])
const props = defineProps({
    id: {
        type: String,
        default: () => `field-${uuid()}`
    },
    type: {
        type: String,
        default: 'text'
    },
    inputDir: String,
    optionValue: String,
    optionLabel: {
        type: String,
        default: 'label'
    },
    label: String,
    required: Boolean,
    error: String,
    help: String,
    placeholder: String,
    modelValue: [String, Number],
    leftIcon: String,
    rightIcon: String,
    options: Array,
    maxHeight: {
        required: false,
        type: String,
        default: '100'
    },
    clearable: {
        required: false,
        type: Boolean,
        default: false
    },
    searchable: {
        required: false,
        type: Boolean,
        default: true
    },
    hideLabel: {
        type: Boolean,
        required: false,
        default: false
    },
    hideErrors: {
        type: Boolean,
        required: false,
        default: false
    }
});
    
const ariaDescribedBy = computed(() => {
    return props.help ? `help-${uuid()}` : null;
});

const cssVars = ref({ '--max-height': props.maxHeight + 'px' })

const value = computed({
    get: () => props.modelValue,
    set: v => emit('update:modelValue', v)
})

const vselect = ref(null)
const i18n = useI18n();

onMounted(() => {
    vselect.value.$refs.toggle.classList.add(...'tw-w-full tw-h-[40px] tw-outline-none tw-text-sm tw-rounded-md tw-border-none tw-px-2 tw-duration-200'.split(' '))
    if(props.leftIcon) {
        if(i18n.locale.value == 'ar') {
            vselect.value.$refs.toggle.classList.add('tw-pr-10')
        } else {
            vselect.value.$refs.toggle.classList.add('tw-pl-10')
        }
    }

    if(props.rightIcon) {
        if(i18n.locale.value == 'ar') {
            vselect.value.$refs.toggle.classList.add('tw-pl-10')
        } else {
            vselect.value.$refs.toggle.classList.add('tw-pr-10')
        }
    }
})

</script>

<style>
@import "vue-select/dist/vue-select.css";

.vs__dropdown-menu {
    max-height: var(--max-height);
}
</style>