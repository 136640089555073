<template>
    <div :dir="$root.$i18n.locale == 'ar' ? 'rtl' : 'ltr'">
        <div class="tw-grid tw-grid-cols-12 tw-min-h-[100dvh] tw-relative">
            <div class="tw-relative tw-pb-24 tw-col-span-12 md:tw-col-span-7 lg:tw-col-span-5 2xl:tw-col-span-4 tw-h-full tw-flex tw-items-center tw-justify-center tw-gap-2 tw-flex-col tw-p-5">
                
                
                <form @submit="register" class="tw-w-full lg:tw-max-w-[350px] tw-mx-auto" action="">
                    <div class="tw-w-full lg:tw-max-w-[350px]">
                        <AppLogo />
                    </div>

                    <h1 class="tw-mt-5 lg:tw-text-4xl tw-text-2xl tw-w-full tw-text-start tw-font-bold tw-text-gray-800">
                        {{ $t('pages.register.welcome') }} <span class="tw-text-primary-500">!</span>
                    </h1>
                    <p class="tw-mb-7 tw-mt-2 tw-text-md tw-font-semibold">{{ $t('pages.register.welcome-paragraph') }}</p>

                    <FormField
                        v-model="user.name"
                        input-dir="ltr"
                        type="text"
                        :placeholder="$t('fields.fullname')"
                        left-icon="solar:user-bold-duotone"
                        :label="$t('fields.fullname')"
                        :error="errors.name && $t('errors.' + errors.name, { field: $t('fields.fullname') })"
                    />

                    <div class="tw-mt-1"></div>

                    <FormField
                        v-model="user.email"
                        input-dir="ltr"
                        type="email"
                        placeholder="example@email.com"
                        left-icon="solar:letter-bold-duotone"
                        :label="$t('fields.email')"
                        :error="errors.email && $t('errors.' + errors.email, { field: $t('fields.email') })"
                        />

                    <div class="tw-mt-1"></div>
                    
                    <FormField
                        v-model="user.password"
                        input-dir="ltr"
                        placeholder="********"
                        left-icon="solar:lock-password-bold-duotone"
                        type="password"
                        :label="$t('fields.password')"
                        :error="errors.password && $t('errors.' + errors.password, { field: $t('fields.password') })"
                    />

                    <div class="tw-mb-7"></div>

                    <a-button intent="primary" :loading="isFetching" :disabled="isFetching" full>
                        {{ $t('buttons.sign-up') }}
                    </a-button>
                    <div class="tw-flex tw-w-full tw-text-sm tw-justify-center tw-text-gray-700 tw-mt-3">
                        <p>
                            {{ $t('pages.register.already-have-account') }}
                            <router-link 
                                :to="{ name: 'auth.login' }"
                                type="button"
                                class="tw-text-primary-500 tw-px-1 tw-font-semibold tw-duration-200 hover:tw-text-primary-600"
                            >{{ $t('buttons.sign-in') }}</router-link>
                        </p>
                    </div>

                </form>

                <div class="tw-flex tw-items-center tw-justify-between tw-absolute tw-bottom-0 tw-p-5 tw-text-center tw-w-full">
                    <p class="tw-text-sm tw-text-gray-600">Copyright &copy; {{ (new Date()).getFullYear() }} CodClient. All rights reserved.</p>
                    <AppLanguageSwitcher />
                </div>
            </div>

            <div class="tw-col-span-12 md:tw-col-span-5 lg:tw-col-span-7 2xl:tw-col-span-8 tw-relative tw-h-full tw-hidden md:tw-block">
                <img :src="$frontend('assets/images/backgrounds/login-bg.jpg')" class="tw-h-full tw-w-full tw-object-cover tw-bg-left">
                <div class="tw-absolute tw-top-0 tw-left-0 tw-w-full tw-h-full tw-z-10 tw-flex tw-items-center tw-justify-center  tw-drop-shadow-2xl">
                    <div class="tw-relative tw-p-3 tw-rounded-2xl tw-bg-red-50x0 tw-overflow-hidden">
                        <div style="animation-duration: 10s;" class="tw-w-full tw-animate-spin tw-h-full tw-absolute tw-left-0 tw-top-0">
                            <div class="tw-w-[40px]  tw-h-full tw-bg-primary-300 tw-absolute tw-left-1/2 -tw-translate-x-1/2 -tw-translate-y-1/2 tw-top-0"></div>
                        </div>

                        <div class="tw-w-full tw-h-full tw-bg-primary-300 tw-absolute tw-top-0 tw-left-0 tw-opacity-75"></div>
                        <div class="tw-flex tw-flex-col tw-justify-center tw-shadow-lg tw-duration-200 tw-items-center tw-p-3 tw-from-primary-300 tw-via-primary-500 tw-to-primary-600 tw-bg-gradient-to-tr tw-border tw-border-b-4 tw-border-primary-600 tw-relative tw-ring-8x tw-ring-primary-400x tw-rounded-lg tw-overflow-hidden">
                            <icon icon="solar:box-line-duotone"  class="tw-text-9xl tw-text-primary-900"/>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script setup>
import FormField from '@/components/form/FormField';
import AppLogo from '@/components/AppLogo'
import AppLanguageSwitcher from '@/components/AppLanguageSwitcher'
import registerService from '@/services/auth/register';
import { reactive } from 'vue';


const user = reactive({
    name: '',
    email: '',
    password: ''
})

const {errors, isFetching, execute} = registerService(user);

const register = $e => {
    $e.preventDefault();
    
    errors.name = "";
    errors.email = "";
    errors.password = "";

    if(!user.name) {
        errors.name = 'required';
    }

    if(!user.email) {
        errors.email = 'required';
    }

    if(!user.password) {
        errors.password = 'required';
    }

    if(!user.password || !user.email || !user.name) return false;

    execute();
}
</script>
<style>
    
</style>