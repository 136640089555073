import {
    server
} from "@/api";


export const login = async (user) => {
    return await server().post('api/auth/login', user)
}

export const register = async (user) => {
    return await server().post('api/auth/register', user);
}

export const current = async () => {
    return await server().get('api/auth/current')
}

export const logout = async () => {
    return await server().post('api/auth/logout')
}

export default {
    login,
    register,
    current,
    logout
}