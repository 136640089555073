import { PREFIX } from "@/config/app"

export const saveInLocalStorage = (key, value) => {
    localStorage.setItem(PREFIX + key, JSON.stringify(value));
}

export const getFromLocalStorage = (key, defaultValue = null) => {
    const value = localStorage.getItem(PREFIX + key);
    try {
        return JSON.parse(value)
    } catch {
        return defaultValue
    }
}
