
import axios from "axios";
import { request } from "./interceptors/request";
import { resolve, reject } from "./interceptors/response";
import { BACKEND_URL } from "@/config/app";
import Cookie from 'js-cookie';

const defaultOptions = {
    Accept:'application/json',
    'Content-Type': 'application/json'
}

export const server = (url = BACKEND_URL, headers = defaultOptions) => {

    headers['cod_client_session'] = Cookie.get('cod_client_session');
    headers['XSRF-TOKEN'] = Cookie.get('X-XSRF-TOKEN');

    const api = axios.create({
        baseURL: url,
        withCredentials: true,
        headers,
    });

    // adding interceptors
    api.interceptors.request.use(request);
    api.interceptors.response.use(resolve, reject);

    return api;
};
