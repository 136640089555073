import {
    current
} from "@/api/auth";
import { ref } from "vue";


export default () => {

    const isFetching = ref(false);

    const success = async (response) => {

        if (response.data.code != 'SUCCESS') {
            return false;
        }

    }

    const failed = async (error) => {

        if (error?.response?.data?.code != 'SUCCESS') {
            return false;
        }

    }


    const check = async () => {
        isFetching.value = true;
        await current()
        .then(success)
        .catch(failed)
        isFetching.value = false;
    }


    return {
        check,
        isFetching,
    }

}