<template>
    <div dir="ltr" class="tw-flex tw-items-center tw-gap-2">

        <div class="tw-w-[40px] tw-text-xl tw-font-bold tw-text-white tw-flex tw-items-center tw-justify-center tw-h-[40px] tw-bg-primary-500 tw-rounded-lg  tw-border-4 tw-border-primary-300">
            <icon 
            class="tw-text-2xl"
            icon="solar:box-line-duotone" />
        </div>
        <div class="tw-text-lg tw-font-black tw-text-gray-700 tw-tracking-wide tw-flex tw-flex-col tw-justify-center">
            <span class="tw-text-primary-600">COD</span>
            <span class="tw-block -tw-mt-2.5">CLIENT</span>
        </div>
    </div>
</template>