<template>
    <component :disabled="props.disabled" :is="props.as" :class="buttonClass">
        <icon v-if="props.leftIcon" :icon="props.leftIcon" :class="[
            'tw-text-base',
            props.loading && 'tw-invisible'
        ]" />

        <span v-if="!props.icon" class="tw-whitespace-normal" :class="[
            props.loading && 'tw-invisible'
        ]">
            <slot />
        </span>

        <icon v-if="props.icon" :icon="props.icon" :class="[
            'tw-text-base',
            props.loading && 'tw-invisible'
        ]" />

        <icon v-if="props.rightIcon" :icon="props.rightIcon" :class="[
            'tw-text-base',
            props.loading && 'tw-invisible'
        ]" />

        <icon v-if="props.loading" class="tw-absolute tw-text-base" icon="line-md:loading-twotone-loop" />
    </component>
</template>
<script setup>
import { defineProps, computed } from 'vue';
import { cva } from 'class-variance-authority';

const props = defineProps({
    leftIcon: String,
    rightIcon: String,
    loading: Boolean,
    disabled: Boolean,
    full: Boolean,
    icon: String,

    as: {
        type: [String, Object],
        default: 'button'
    },

    intent: {
        type: String,
        default: 'primary',
        validator: (v) => {
            return ['primary', 'secondary', 'danger', 'text', 'simple', 'info', 'warning', 'purple'].includes(v)
        }
    }
});

const buttonClass = computed(() => {
    return cva("tw-relative tw-inline-flex tw-border tw-cursor-pointer active:tw-translate-y-0.5 active:tw-shadow-none active:!tw-border-b-2 tw-border-solid  tw-items-center tw-justify-center tw-duration-200  tw-text-sm tw-rounded tw-min-h-[20px] tw-px-1 tw-aspect-square tw-py-0.5 tw-font-semibold tw-gap-2", 
    {
        variants: {
            intent: {
                primary: "tw-bg-primary-500 tw-text-white  tw-border-primary-600",
                secondary: "tw-bg-primary-100 tw-text-primary-600  hover:tw-text-primary-700  tw-border-solid tw-border tw-border-primary-200",
                dark: "tw-bg-gray-100 tw-text-gray-600  tw-border-gray-200",
                danger: "tw-bg-rose-500 tw-text-white  tw-border-rose-600",
                text: "tw-text-primary-500 tw-border-transparent hover:tw-border-primary-200",
                simple: "tw-text-neutral-700 hover:tw-text-primary-700 hover:tw-border-primary-400",
                warning: "tw-bg-amber-500 tw-text-white  tw-border-amber-600",
                info: "tw-bg-cyan-500 tw-text-white  tw-border-cyan-600",
                purple: "tw-bg-purple-500 tw-text-white  tw-border-purple-600",
            },
            disabled: {
                true: "!tw-bg-gray-200 !tw-text-gray-500  !tw-cursor-not-allowed !tw-border-gray-300/70 !tw-shadow-none active:!tw-border-b-2 active:!tw-translate-y-0"
            },
            full: {
                true: "!tw-w-full"
            }
        }
    })({ intent: props.intent, disabled: props.disabled, full: props.full });
});

</script>
<style></style>