export const formatFullName = (str) => {
        // Split the string into words
        const words = str.split(' ');
      
        // Extract the first letter of each word
        const initials = words.map(word => word[0]);
      
        // Join the initials, except for the last one
        const result = initials.slice(0, -1).join('.') + ' ' + words.slice(-1);
      
        return result;
}