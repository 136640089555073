import DashboardLayout from "@/layouts/dashboard/DashboardLayout";

import orders from "./orders"

export default [
  {
    path: "/agent",
    component: DashboardLayout,
    meta: {
      middleware: ["auth"],
      roles: ["agent"],
    },
    children: [
      ...orders
    ],
  },
];
