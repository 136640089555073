<template>
  <div class="tw-hidden md:tw-block">
    <FormField
      v-model="search"
      input-dir="ltr"
      placeholder="Order ID, Client..."
      left-icon="clarity:search-line"
      type="text"
      class="tw-min-w-[400px]"
    />
  </div>
</template>

<script setup>
import FormField from "@/components/form/FormField";
import { ref } from "vue";

const search = ref("");
</script>

<style></style>
