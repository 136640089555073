import { useAuthStore } from "@/stores";

export default (to) => {

    if(!to.meta?.middleware?.includes('guest')) return true;

    const authStore = useAuthStore();
    if(!authStore.isLoggedIn) 
        return true;

    if(authStore.isLoggedIn) 
        return { name: 'dashboard' };

    return true;
}