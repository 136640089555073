<template>
  <v-app full-height>
    <v-main>
      <router-view/>
    </v-main>

    <app-alert />
  </v-app>
</template>

<script>
import AppAlert from './components/AppAlert.vue';
import { saveInLocalStorage } from './utils/local-storage';

export default {
  components: { AppAlert },

  name: 'App',

  data: () => ({
    
  }),

  watch: {
    '$i18n.locale': function(v) {
      document.querySelector('html')
      .setAttribute('dir', v == 'ar' ? 'rtl' : 'ltr');
      saveInLocalStorage('language', v);
    }
  },
  
}
</script>

<style>
@import url('https://fonts.googleapis.com/css2?family=Cairo:wght@200;300;400;500;600;700;800;900;1000&display=swap');
</style>