import session from "@/services/auth/session";
import { useAuthStore } from "@/stores";

export default (to) => {
    
    if(!to.meta?.middleware?.includes('auth')) return true;
    
    const authStore = useAuthStore();
    const user = authStore.user;

    if(!authStore.isLoggedIn && ['auth.register', 'auth.login'].includes(to.name))
        return true;

    if(!authStore.isLoggedIn) 
        return { name: 'auth.login' };

    session().check();

    if(!user) {
        authStore.logout();
        return { name: 'auth.login' };
    }

    if(to.meta?.roles?.length &&!to.meta?.roles?.includes(user.role)){
        return { name: 'dashboard' };
    }

    return true;
}