import OrderListView from "@/views/admin/orders/OrderListView";

export default [
  {
    name: "pages.admin.orders",
    path: "orders",
    component: OrderListView
  },
  
];
