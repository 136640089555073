<template>
  <div ref="container" class="tw-relative">
    <button
      @click="isActive = !isActive"
      class="active:tw-translate-y-0.5 active:tw-border-b active:tw-shadow-none tw-p-1.5 tw-border tw-border-b-2 tw-border-solid tw-rounded-lg hover:tw-bg-neutral-50/10 hover:tw-shadxow-md hover:tw-shadow-neutral-200 tw-duration-200"
    >
      <icon
        class="tw-text-2xl tw-text-neutral-600 tw-duration-200"
        icon="solar:user-broken"
      />
    </button>

    <transition
      class="tw-duration-200"
      enter-from-class="tw-translate-y-[10px]  tw-opacity-0"
      leave-to-class="tw-translate-y-[10px]  tw-opacity-0"
    >
      <div v-if="isActive">
        <div
          :class="[$root.$i18n.locale == 'ar' ? 'tw-left-0' : 'tw-right-0']"
          class="tw-w-fit tw-min-w-[200px] tw-shadow-md tw-max-w-[250px] tw-h-fit tw-overflow-hidden tw-absolute -tw-bottom-1 tw-translate-y-full tw-bg-white tw-rounded-lg tw-border tw-border-b-2"
        >
          <div class="tw-w-full tw-h-full tw-pb-3">
            <div class="tw-flex tw-gap-2 tw-p-2 tw-items-center tw-pb-2 tw-border-b tw-border-gray-100">
              <div class="tw-w-[45px] tw-h-fit tw-text-2xl tw-overflow-hidden tw-aspect-square tw-bg-gray-100 tw-border-2 tw-border-solid tw-border-y-gray-200 tw-border-x-gray-200 tw-rounded-full tw-flex tw-items-center tw-justify-center">
                <icon class="tw-text-gray-500  tw-text-8xl" icon="material-symbols-light:emoji-nature" />
                <!-- <img :src="$frontend('assets/images/avatars/avatar.png')" class="tw-w-full tw-aspect-square tw-h-full tw-object-cover" alt=""> -->
              </div>

              <div class="tw-py-1">
                <h2 class="tw-text-gray-600 tw-font-semibold tw-whitespace-nowrap tw-truncate tw-text-sm">{{ visibleName }}</h2>
                <h3 class="tw-font-semibold tw-whitespace-nowrap tw-truncate tw-text-xs tw-px-1 tw-w-fit tw-rounded-sm tw-mt-0.5 tw-bg-blue-100 tw-text-blue-500 tw-capitalize">{{ authStore.user.role }}</h3>
              </div>
            </div>

            <div class="tw-w-full tw-mt-3">
              <button @click="isActive = false" class="tw-flex tw-items-center tw-gap-2 tw-p-2 tw-px-5 hover:tw-bg-gray-50 tw-duration-200 tw-w-full">
                <icon class="tw-text-xl tw-text-gray-500" icon="solar:user-circle-bold-duotone" />
                <span class="tw-text-gray-500 tw-text-sm tw-font-medium">{{ $t('buttons.my-account') }}</span>
              </button>

              <button :disabled="isLogginOut" @click="handleLogout" 
              :class="[isLogginOut && '!tw-text-gray-300 !tw-bg-gray-50']"
              class="tw-flex tw-items-center tw-gap-2 tw-text-red-500 tw-p-2 tw-px-5 hover:tw-bg-gray-50 tw-duration-200 tw-w-full">
                <icon class="tw-text-xl" :icon="isLogginOut ? 'line-md:loading-alt-loop' : 'solar:logout-3-bold-duotone'" />
                <span class="tw-text-sm tw-font-medium">{{ $t('buttons.logout') }}</span>
              </button>
            </div>
          </div>
      </div>
      </div>
    </transition>
  </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { onClickOutside } from "@vueuse/core";
import { formatFullName } from '@/utils/string'
import { useAuthStore } from "@/stores";
import { useRouter } from "vue-router";
import { logout } from '@/api/auth'

const isActive = ref(false);
const container = ref(null);
const authStore = useAuthStore();
const router = useRouter();
const isLogginOut = ref(false);

const visibleName = computed(() => formatFullName(authStore.user.name));

onClickOutside(container, () => (isActive.value = false));

const handleLogout = async () => {
  isLogginOut.value = true;
  await logout();
  
  authStore.logout();
  router.push({ name: 'auth.login' });
  isLogginOut.value = false;

}
</script>

<style>
</style>