<template>
    <div class="tw-p-2 tw-bg-white tw-w-full tw-border tw-border-solid tw-flex tw-items-center tw-gap-2">
        <router-link :to="{ name: 'pages.dashboard.agent' }" class="tw-text-gray-400 tw-duration-200 hover:tw-text-gray-700 tw-text-xl tw-flex tw-items-center tw-gap-2"><icon icon="solar:home-smile-bold-duotone" /> <span class="tw-text-sm">{{ $t("dashboard.sidebar.dashboard") }}</span></router-link>
        <span class="tw-text-gray-300">/</span>
        <button disabled class="tw-text-primary-500 tw-text-xl tw-flex tw-items-center tw-gap-2"><span class="tw-text-sm">{{ $t("dashboard.sidebar.my-orders") }}</span></button>
    </div>
    
    <div class="tw-p-2">
        <div
            class="tw-h-full tw-w-full tw-p-4 tw-bg-white tw-rounded tw-border tw-border-b-2 tw-min-h-[calc(100dvh-125px)]">
            <div class="tw-justify-between tw-flex tw-items-center tw-h-[40px] tw-gap-2">

                <div class="tw-flex tw-items-center tw-gap-2">
                    <FormField v-model="search" input-dir="ltr" placeholder="Product ID, Name..."
                        left-icon="clarity:search-line" type="text" class="tw-min-w-[120px]" />
                    <a-button v-if="false" class="tw-scale-9x0" left-icon="solar:align-vertical-spacing-line-duotone"
                        intent="simple">{{
                            $t("buttons.columns") }}</a-button>
                    <a-button v-if="false" class="tw-scale-9x0" intent="simple" icon="solar:filter-bold-duotone"></a-button>
                </div>
                <CreateOrderPopup />
            </div>
            <div class="tw-mt-5 tw-roundedx tw-overflow-hiddenx">
                <OrderTable :loading="loading" :items="orders" :options="options" @next="onNext" @previous="onPrevious" />
            </div>
        </div>

    </div>
</template>

<script setup>
import { ref , provide} from "vue";
import { paginate } from "@/api/orders";
import FormField from "@/components/form/FormField";
import OrderTable from "@/views/agent/orders/partials/table/OrderTable";
import CreateOrderPopup from '@/components/orders/CreateOrderPopup'
import { useStateStore } from "@/stores/app/state";

const stateStore = useStateStore();
const search = ref("");
const loading = ref(true);
const orders = ref([]);
const options = ref({})

async function fetchOrders(page = 1) {
    loading.value = true;
    orders.value = []
    await paginate({ per_page: 10, page })
        .then(
            ({ data }) => {
                const { data: items, ...ops } = data.data;
                options.value = ops
                orders.value = items;
                stateStore.setCountOrders(options.value.total);
            },
            (error) => {
                console.log(error);
            }
        );
    loading.value = false;

    console.log();
}


const onUpdate = (order) => {
    orders.value = orders.value.map((item) => item.id == order.id ? order : item);
}

const onNext = () => {
    fetchOrders(options.value.current_page + 1)
}

const onPrevious = () => {
    fetchOrders(options.value.current_page - 1)
}

provide("updateOrder", onUpdate);
fetchOrders();
</script>


<style>

</style>