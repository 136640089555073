import { createApp } from 'vue'
import { DEFAULT_LANGUAGE } from './config/app'

// components
import App from './App.vue'
import Button from '@/components/AppButton'
import ActionButton from '@/components/AppActionButton'
import Popup from '@/components/AppPopup'
import { Icon } from '@iconify/vue'

// plugins
import router from './router'
import vuetify from './plugins/vuetify'
import i18n from '@/plugins/i18n'
import { loadFonts } from './plugins/webfontloader'
import { createPinia } from 'pinia';
import globalProperties from './plugins/global-properties'
import '@/assets/app.css';

loadFonts();

createApp(App)
  .use(router)
  .use(vuetify)
  .use(createPinia())
  .use(i18n(DEFAULT_LANGUAGE))
  .use(globalProperties)

  .component('icon', Icon)
  .component('a-button', Button)
  .component('a-action-button', ActionButton)
  .component('a-popup', Popup)
  .mount('#app')
