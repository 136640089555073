import SheetListView from "@/views/admin/sheets/SheetListView";

export default [
  {
    name: "pages.admin.sheets",
    path: "sheets",
    component: SheetListView
  },
  
];
