import { getFromLocalStorage } from '@/utils/local-storage';
import { createI18n } from 'vue-i18n'

// messages
const messages = {
    en: require('@/locales/en.json'),
    fr: require('@/locales/fr.json'),
    ar: require('@/locales/ar.json'),
  };
  
const language = getFromLocalStorage('language', null);
  
export default (lang = 'en') => createI18n({
    locale: !language ? lang : language, 
    legacy: false,
    fallbackLocale: 'en', 
    messages
  });