<template>
    <a-popup :visible="visible" @cancel="onCancel">
        <div
            class="tw-mx-auto tw-w-[95%] md:tw-max-w-[800px] tw-relative tw-h-fit tw-overflow-auto tw-flex tw-flex-col tw-border tw-border-solid dark:tw-border-gray-400 tw-border-gray-300 tw-shadow-md tw-my-16 tw-rounded">
            <div class="tw-relative tw-bg-white">
                <div class="tw-flex tw-gap-5 tw-p-5 tw-items-center">
                    <div class="tw-bg-rose-100 tw-aspect-square tw-rounded-md tw-h-fit tw-p-2">
                        <icon icon="solar:danger-triangle-bold-duotone" class="tw-text-3xl tw-text-rose-500" />
                    </div>
                    <div class="tw-text-lg tw-flex tw-flex-col tw-gap-2 tw-bg-white tw-pr-10" :class="[$root.$i18n.locale == 'ar' && '!tw-pl-10 !tw-pr-0']">
                        <p class="tw-text-lg tw-font-bold">{{$t('messages.are-you-sure-you-want-to-delete')}}</p>
                    </div>
                </div>
                <p class="tw-text-base tw-px-5 tw-font-medium tw-text-gray-500">{{ $t('messages.action-cannot-be-reverted') }}</p>

                <button @click="onCancel"
                    :class="[$root.$i18n.locale == 'ar' ? '!tw-left-5' : 'tw-right-5 ']"
                    class="tw-absolute tw-top-5 tw-p-1 tw-bg-gray-50 tw-w-fit tw-rounded tw-border tw-border-solid tw-border-gray-200 tw-duration-200 hover:tw-bg-gray-100 tw-cursor-pointer tw-flex tw-items-center tw-justify-center">
                    <icon icon="eva:close-fill" class="tw-text-xl tw-text-gray-500" />
                </button>

                <div class="tw-bg-white tw-p-5 tw-flex tw-items-center tw-gap-2 tw-justify-end">
                    <a-button intent="simple" :disabled="props.loading" @click="onCancel">{{ $t('buttons.no-cancel') }}</a-button>
                    <a-button intent="danger" :disabled="props.loading" :loading="props.loading" @click="onConfirm" class="tw-min-w-[100px]">{{ $t('buttons.delete') }}</a-button>
                </div>
            </div>
        </div>
    </a-popup>
</template>

<script setup>
import { defineEmits, defineProps, toRef } from 'vue';

const props = defineProps(['visible', 'loading']);
const emit = defineEmits(['confirm', 'cancel']);
const visible = toRef(props, 'visible');

const onConfirm = () => {
    emit('confirm');
}

const onCancel = () => {
    if(!props.loading) {
        emit('cancel');
    }
}

</script>

<style></style>