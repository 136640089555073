<template>
    <label 
        class="tw-mb-1 tw-inline-block tw-text-sm tw-font-medium tw-text-gray-700 tw-capitalize"
        :for="props.for"
    >
        <slot />
        <span v-if="props.required" class="tw-text-red-400 tw-text-xs">*</span>
    </label>
</template>
<script setup>
import { defineProps } from 'vue';

const props = defineProps({
    for: String,
    required: Boolean
});
</script>
<style>
    
</style>