<template>
    <div>
        <a-action-button disabled @click="visible = true" icon="solar:refresh-line-duotone" intent="purple"></a-action-button>

        <a-popup :visible="visible" @cancel="onCancel">
            <div
                class="tw-mx-auto tw-w-[95%] md:tw-max-w-[800px]  tw-h-fit tw-overflow-auto tw-flex tw-flex-col tw-border tw-border-solid dark:tw-border-gray-400 tw-border-gray-300 tw-shadow-md tw-my-16 tw-rounded">
                <div>
                    <div
                        class="tw-p-4 tw-text-lg tw-flex tw-items-center tw-gap-2 tw-border-b tw-border-solid tw-font-semibold  tw-text-gray-500 tw-bg-gray-50">
                        <span>{{ $t("buttons.refresh") }}</span>
                    </div>

                    <div class="tw-p-5 tw-bg-white tw-flex-1">
                        <div class="tw-grid tw-grid-cols-12 tw-gap-x-4 tw-gap-y-0">
                        </div>
                    </div>
                    <div
                        v-if="false"
                        class="tw-p-4 tw-text-lg tw-border-t tw-justify-end tw-border-solid tw-font-medium tw-flex  tw-text-gray-500 tw-items-center tw-gap-4 tw-bg-gray-50">
                        <a-button :disabled="loading" @click="onCancel" class="tw-min-w-[120px]" intent="simple">{{
                            $t("buttons.cancel") }}</a-button>
                        <a-button :disabled="loading" :loading="loading" @click="onUpdate" class="tw-min-w-[120px]">{{
                            $t("buttons.update") }}</a-button>
                    </div>
                </div>


            </div>

        </a-popup>
    </div>
</template>

<script setup>

import { ref } from "vue";

const visible = ref(false)
const loading = ref(false)

const onCancel = () => {
    visible.value = false
}

const onUpdate = () => {
    loading.value = true
    // setTimeout(() => {
    //     loading.value = false
    //     visible.value = false
    // }, 1000)
}

</script>

<style></style>