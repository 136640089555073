<template>
    <div>
        <FormLabel 
            v-if="!props.hideLabel"
            :for="props.id" 
            :required="props.required"
            >{{ props.label }}</FormLabel>

        <FormInput 
            v-if="!['select', 'textarea'].includes(type)"
            v-model="value" 
            :dir="!!props.inputDir ? props.inputDir : $root.$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
            :required="props.required"
            :id="props.id" 
            :type="type"
            :aria-described-by="ariaDescribedBy"
            :placeholder="placeholder"
            :left-icon="props.leftIcon"
            :right-icon="props.rightIcon"
            :readonly="readonly"
            :input-class="inputClass"
            :invalid="!!props.error" />

        <FormSelect
            v-if="type == 'select'"
            v-model="value" 
            :dir="!!props.inputDir ? props.inputDir : $root.$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
            :required="props.required"
            :id="props.id" 
            :type="type"
            :aria-described-by="ariaDescribedBy"
            :placeholder="placeholder"
            :left-icon="props.leftIcon"
            :right-icon="props.rightIcon"
            :options="props.options"
            :invalid="!!props.error" />

        <FormTextArea
            v-if="type == 'textarea'"
            v-model="value" 
            :dir="!!props.inputDir ? props.inputDir : $root.$i18n.locale == 'ar' ? 'rtl' : 'ltr'"
            :required="props.required"
            :id="props.id" 
            :type="type"
            :aria-described-by="ariaDescribedBy"
            :placeholder="placeholder"
            :left-icon="props.leftIcon"
            :right-icon="props.rightIcon"
            :invalid="!!props.error" />

        <FormErrorMessage v-if="!props.hideErrors"
            
            >{{ props.error }}</FormErrorMessage>

        <FormHelperMessage 
        :id="ariaDescribedBy"
        v-if="!!props.help"
        >{{ props.help }}</FormHelperMessage>
    </div>
</template>

<script setup>
import FormErrorMessage from './FormErrorMessage.vue';
import FormHelperMessage from './FormHelperMessage.vue';

import FormInput from './FormInput.vue';
import FormSelect from './FormSelect.vue';
import FormTextArea from './FormTextArea.vue';
import FormLabel from './FormLabel.vue';
import { defineEmits, defineProps, computed } from 'vue';
import { v4 as uuid } from 'uuid'

const emit = defineEmits(['update:modelValue'])
const props = defineProps({
    id: {
        type: String,
        default: () => `field-${uuid()}`
    },
    type: {
        type: String,
        default: 'text'
    },
    inputDir: String,
    label: String,
    required: Boolean,
    error: String,
    help: String,
    placeholder: String,
    modelValue: [String, Number],
    leftIcon: String,
    rightIcon: String,
    options: Array,
    readonly: Boolean,
    hideLabel: {
        type: Boolean,
        required: false,
        default: false
    },
    hideErrors: {
        type: Boolean,
        required: false,
        default: false
    },
    inputClass: String
})
    
const ariaDescribedBy = computed(() => {
    return props.help ? `help-${uuid()}` : null;
});

const value = computed({
    get: () => props.modelValue,
    set: v => emit('update:modelValue', v)
})
</script>

<style scoped>

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}
</style>