export const order_confirmations = [
    { name: 'order-status.confirmation.new', value: 'new', color: 'tw-bg-gray-100 tw-text-black' },
    { name: 'order-status.confirmation.confirmed', value: 'confirmed', color: 'tw-bg-emerald-500 tw-text-white' },
    { name: 'order-status.confirmation.no-answer', value: 'no-answer', color: 'tw-bg-rose-700 tw-text-white' },

    // { name: 'order-status.confirmation.day-one-call-one', value: 'day-one-call-one', color: 'tw-bg-gray-600 tw-text-white' },
    // { name: 'order-status.confirmation.day-one-call-two', value: 'day-one-call-two', color: 'tw-bg-gray-700 tw-text-white' },
    // { name: 'order-status.confirmation.day-one-call-three', value: 'day-one-call-three', color: 'tw-bg-gray-800 tw-text-white' },

    // { name: 'order-status.confirmation.day-two-call-one', value: 'day-two-call-one', color: 'tw-bg-amber-600 tw-text-white' },
    // { name: 'order-status.confirmation.day-two-call-two', value: 'day-two-call-two', color: 'tw-bg-amber-700 tw-text-white' },
    // { name: 'order-status.confirmation.day-two-call-three', value: 'day-two-call-three', color: 'tw-bg-amber-800 tw-text-white' },

    // { name: 'order-status.confirmation.day-three-call-one', value: 'day-three-call-one', color: 'tw-bg-rose-600 tw-text-white' },
    // { name: 'order-status.confirmation.day-three-call-two', value: 'day-three-call-two', color: 'tw-bg-rose-700 tw-text-white' },
    // { name: 'order-status.confirmation.day-three-call-three', value: 'day-three-call-three', color: 'tw-bg-rose-800 tw-text-white' },

    { name: 'order-status.confirmation.wrong-number', value: 'wrong-number', color: 'tw-bg-gray-500 tw-text-white' },
    { name: 'order-status.confirmation.reported', value: 'reported', color: 'tw-bg-blue-500 tw-text-white' },
    { name: 'order-status.confirmation.canceled', value: 'canceled', color: 'tw-bg-red-500 tw-text-white' },
    { name: 'order-status.confirmation.refund', value: 'refund', color: 'tw-bg-purple-500 tw-text-white' },
    { name: 'order-status.confirmation.change', value: 'change', color: 'tw-bg-cyan-500 tw-text-white' },
    { name: 'order-status.confirmation.duplicate', value: 'duplicate', color: 'tw-bg-gray-100 tw-text-blue-500' },
]


export const order_deliveries = [
    { name: 'order-status.delivery.not-selected', value: 'not-selected', color: 'tw-bg-gray-100 tw-text-black' },
    { name: 'order-status.delivery.dispatched', value: 'dispatched', color: 'tw-bg-blue-500 tw-text-white' },
    { name: 'order-status.delivery.reserved-before-delivery', value: 'reserved-before-delivery', color: 'tw-bg-yellow-400 tw-text-black' },
    { name: 'order-status.delivery.sent-for-delivery', value: 'sent-for-delivery', color: 'tw-bg-indigo-500 tw-text-white' },
    { name: 'order-status.delivery.in-company', value: 'in-company', color: 'tw-bg-green-500 tw-text-white' },
    { name: 'order-status.delivery.with-courier', value: 'with-courier', color: 'tw-bg-purple-500 tw-text-white' },
    { name: 'order-status.delivery.returned-to-company', value: 'returned-to-company', color: 'tw-bg-red-500 tw-text-white' },
    { name: 'order-status.delivery.return-received', value: 'return-received', color: 'tw-bg-orange-500 tw-text-white' },
    { name: 'order-status.delivery.delivered', value: 'delivered', color: 'tw-bg-emerald-500 tw-text-white' },
    { name: 'order-status.delivery.settled', value: 'settled', color: 'tw-bg-blue-700 tw-text-white' },
    { name: 'order-status.delivery.deleted', value: 'deleted', color: 'tw-bg-black tw-text-white' },
    { name: 'order-status.delivery.return-resent', value: 'return-resent', color: 'tw-bg-pink-500 tw-text-white' },
    { name: 'order-status.delivery.return-lost', value: 'return-lost', color: 'tw-bg-red-700 tw-text-white' },
    { name: 'order-status.delivery.return-destroyed', value: 'return-destroyed', color: 'tw-bg-gray-700 tw-text-white' },
    { name: 'order-status.delivery.postponed-with-courier', value: 'postponed-with-courier', color: 'tw-bg-yellow-500 tw-text-black' },
    { name: 'order-status.delivery.to-courier', value: 'to-courier', color: 'tw-bg-purple-700 tw-text-white' },
    { name: 'order-status.delivery.return-with-courier', value: 'return-with-courier', color: 'tw-bg-red-300 tw-text-black' },
    { name: 'order-status.delivery.on-way-to-branch', value: 'on-way-to-branch', color: 'tw-bg-blue-300 tw-text-black' },
    { name: 'order-status.delivery.in-branch', value: 'in-branch', color: 'tw-bg-green-300 tw-text-black' },
    { name: 'order-status.delivery.return-to-branch', value: 'return-to-branch', color: 'tw-bg-gray-500 tw-text-white' },
    { name: 'order-status.delivery.return-in-branch', value: 'return-in-branch', color: 'tw-bg-gray-600 tw-text-white' },
]
