<template>
  <div class="tw-p-2 tw-bg-white tw-w-full tw-border tw-border-solid tw-flex tw-items-center tw-gap-2">
    <router-link :to="{ name: 'pages.dashboard.admin' }"
      class="tw-text-gray-400 tw-duration-200 hover:tw-text-gray-700 tw-text-xl tw-flex tw-items-center tw-gap-2">
      <icon icon="solar:home-smile-bold-duotone" /> <span class="tw-text-sm">{{ $t("dashboard.sidebar.dashboard")
        }}</span>
    </router-link>
    <span class="tw-text-gray-300">/</span>
    <button disabled class="tw-text-primary-500 tw-text-xl tw-flex tw-items-center tw-gap-2"><span class="tw-text-sm">{{
      $t("dashboard.sidebar.sheets") }}</span></button>
  </div>
  <div class="tw-p-2">
    <div
      class="tw-h-full tw-w-full tw-p-4 tw-bg-white tw-rounded tw-border tw-border-b-2 tw-min-h-[calc(100dvh-125px)]">
      <div class="tw-justify-between tw-flex tw-items-center tw-h-[40px] tw-gap-2">
        <FormField v-model="search" input-dir="ltr" placeholder="Sheet ID, Name..." left-icon="clarity:search-line"
          type="text" class="tw-min-w-[100px]" />
        <sheet-create-popup />
      </div>
      <div class="tw-mt-5 tw-roundedx tw-overflow-hiddenx">
        <SheetTable :loading="loading" :items="sheets" />
      </div>
    </div>

  </div>
</template>

<script setup>
import { ref, provide } from 'vue'
import { paginate } from '@/api/sheets';
import FormField from "@/components/form/FormField";
import SheetTable from '@/views/admin/sheets/partials/table/SheetTable'
import SheetCreatePopup from '@/views/admin/sheets/partials/SheetCreatePopup'

const search = ref('')
const loading = ref(true)
const sheets = ref([]);

async function fetchSheets() {
  loading.value = true;
  await paginate()
    .then(
      ({ data }) => {
        sheets.value = data.data.data;
        console.log(sheets.value)
      },
      error => {
        console.log(error)
      }
    )
  loading.value = false

  console.log()
}

fetchSheets()

const onDeleted = id => {
  sheets.value = sheets.value.filter(s => s.id != id);
}

provide('onDeleted', onDeleted);
</script>

<style></style>