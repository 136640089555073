<template>
    <div :class="[index > 0 && '!tw-bg-purple-50 tw-border-purple-500']"
        class="tw-flex tw-justify-end tw-gap-2 tw-p-2 tw-bg-gray-50 tw-border-t tw-border-solid tw-border-gray-200">
        
        <EditButton :item="props.order" />

        <a-button as="a" :href="'tel:' + props.order.customer_phone" intent="info" left-icon="solar:phone-bold-duotone"
            class="tw-min-w-[100px]">
            {{ $t("buttons.call") }}
        </a-button>
    </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';
import EditButton from './actions/EditButton';

const props = defineProps(['order', 'index']);
const emit = defineEmits(['update'])
emit


</script>

<style></style>