<template>
  <v-layout 
    v-if="authStore.isLoggedIn" 
    :dir="$root.$i18n.locale == 'ar' ? 'rtl' : 'ltr'" 
    :full-height="true"
    class="rounded rounded-md"
    >

    <dashboard-sidebar v-model:drawer="drawer" />

    <dashboard-header v-model:drawer="drawer" />


    <v-main class="tw-min-h-screen tw-h-fit tw-overflow-y-auto tw-bg-gray-100">
      <router-view></router-view>
    </v-main>
  </v-layout>
</template>

<script setup>
import { ref } from "vue";
import DashboardHeader from "./partials/DashboardHeader.vue";
import DashboardSidebar from "./partials/DashboardSidebar.vue";
import { useAuthStore } from "@/stores";
import initial from "@/services/app/initial";
// import { useRoute } from "vue-router";
import { useI18n } from "vue-i18n";


const i18n = useI18n();
// const route = useRoute();
const drawer = ref(true);
const authStore = useAuthStore();

const { execute } = initial();

if(i18n.locale.value == 'ar') {
  document.querySelector('html').setAttribute('dir', 'rtl');
}


// get required data for the app
execute();



</script>
