<template>
    <div>
        <div class="tw-flex tw-items-center tw-justify-between tw-gap-2">
            <a-button :disabled="!props.hasPrevious" @click="emit('previous')" intent="simple"
                :left-icon="$root.$i18n.locale == 'ar' ? 'solar:arrow-right-line-duotone' : 'solar:arrow-left-line-duotone'"
                class="tw-min-w-[120px] !tw-border-b">{{ $t('buttons.previous') }}</a-button>
            <a-button :disabled="!props.hasNext" @click="emit('next')" intent="simple"
                :right-icon="$root.$i18n.locale == 'ar' ? 'solar:arrow-left-line-duotone' : 'solar:arrow-right-line-duotone'"
                class="tw-min-w-[120px] !tw-border-b">{{ $t('buttons.next') }}</a-button>
        </div>
    </div>
</template>

<script setup>
import { defineProps, defineEmits } from 'vue';

const props = defineProps({
    hasNext: Boolean,
    hasPrevious: Boolean
});
const emit = defineEmits(['next', 'previous'])

</script>

<style></style>