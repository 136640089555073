<template>
  <tr
    :key="item.id"
    class="tw-border-b tw-border-solid tw-border-gray-100 last:tw-border-b-0 tw-group even:tw-bg-gray-50/50 hover:tw-bg-primary-50/60 tw-duration-100"
  >
    <td class="tw-px-5 tw-py-3 tw-text-sm">{{ item.id }}</td>
    <td class="tw-px-5 tw-py-3 tw-text-sm">{{ item.name }}</td>
    <td class="tw-px-5 tw-py-3 tw-text-sm">

        <div class="tw-capitalize tw-whitespace-nowrap tw-text-center">
            {{ item.role }}
        </div>
    </td>
    <td class="tw-px-5 tw-py-3 tw-text-sm">
        <p class="tw-text-sm tw-whitespace-nowrap tw-text-center" v-if="item.last_login_at">{{ lastLogged }}</p>
        <p class="tw-text-sm tw-whitespace-nowrap tw-text-center" v-else>-</p>
    </td>
    <td class="tw-px-5 tw-py-3 tw-text-sm">{{ item.login_times ?? 0 }}</td>
    <td class="tw-px-5 tw-pr-0 tw-py-">
      <UserTableActions />
    </td>
  </tr>
</template>

<script setup>
import { defineProps, toRef, computed } from "vue";
import moment from 'moment'
import UserTableActions from "./UserTableActions";
import { useI18n } from "vue-i18n";

const i18n = useI18n();
const props = defineProps(["item"]);
const item = toRef(props, "item");

const lastLogged = computed(() => {
    return moment(item.value.last_login_at).locale(i18n.locale.value).fromNow()
})
</script>

<style></style>
