<template>
    <div class="tw-w-full tw-flex tw-text-center tw-justify-end tw-gap-2 tw-px-4">
        <a-action-button icon="solar:trash-bin-2-bold-duotone" intent="danger"></a-action-button>
    </div>
</template>

<script setup>
</script>

<style>

</style>