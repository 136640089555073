import {
    server
} from "@/api";


export const create = async (user) => {
    return await server().post('api/users', user)
}

export const paginate = async (params) => {
    return await server().get('api/users', { params });
}

export const all = async (params) => {
    return await server().get('api/users', { params: { all: true, ...params } });
}

export const update = async (id, user) => {
    return await server().post(`api/users/${id}`, user);
}

export default {
    create,
    paginate,
    update,
    all
}