<template>
  <div>

    <div class="tw-grid md:tw-grid-cols-2 tw-grid-cols-1 tw-gap-x-4">
      <div class="tw-flex md:tw-items-center tw-gap-2 tw-flex-col md:tw-flex-row tw-grid-cols-1">
      <div class="tw-grid tw-grid-cols-2 tw-gap-2 tw-w-full">
        <div>
          <FormField
                      v-model="variant.variant_name"
                      left-icon="solar:forward-2-bold-duotone"
                      :label="$t('fields.variant_name')"
                      :error="errors.variant_name && $t('errors.' + errors.variant_name, { field: $t('fields.name') })"
                      type="text"
                      class=""
                    />
        </div>
        <div>
          <FormField
                      v-model="variant.quantity"
                      left-icon="solar:box-minimalistic-bold-duotone"
                      input-dir="ltr"
                      :label="$t('fields.quantity')"
                      :error="errors.quantity && $t('errors.' + errors.quantity, { field: $t('fields.quantity') })"
                      type="number"
                      class=""
                    />
        </div>
      </div>
      <a-button @click="handleAdd" intent="simple" left-icon="heroicons:plus-16-solid" class="tw-px-2 tw-w-fit">{{ $t("buttons.add") }}</a-button>
    </div>
    </div>
    


    <div
      class="tw-mt-4 tw-grid tw-grid-cols-12 tw-gap-x-4 tw-gap-y-2 tw-overflow-hidden"
    >
      <div v-if="!variants.length" class="tw-col-span-12 md:tw-col-span-6 tw-p-5 tw-bg-neutral-100">
        {{ variants }}
      </div>

      <div v-else class="tw-col-span-12 md:tw-col-span-6 ">
        <ProductVariantsTable @delete="handleDelete" :variants="variants" />        
      </div>
    </div>
  </div>
</template>

<script setup>
import FormField from "@/components/form/FormField";
import ProductVariantsTable from './ProductVariantsTable'
import { ref, toRef, defineProps, defineEmits } from 'vue'

const props = defineProps(['variants']);
const emit = defineEmits(['update:variants']);
const variants = toRef(props, 'variants');
// const variants = ref([{name: 'XL', quantity: 20}]);

const variant = ref({});
const errors = ref({});

const handleAdd = () => {
  let valid = true;
  errors.value = {}

  if (!variant.value.variant_name) {
    errors.value.variant_name = 'required';
    valid = false;
  }

  if (!variant.value.quantity) {
    errors.value.quantity = 'required';
    valid = false;
  }

  if(!valid) return;

  variant.value.id = Date.now();
  variants.value.push(variant.value)
  variant.value = {};
  emit('update:variants', variants.value);
}


const handleDelete = () => {
  variants.value.splice(variants.value.indexOf(variant.value), 1)
  emit('update:variants', variants.value);
}

</script>

<style></style>
