<template>
    <tr :key="item.id"
        class="tw-border-b tw-border-solid tw-border-gray-100 last:tw-border-b-0 even:tw-bg-gray-50/50 hover:tw-bg-primary-50/60 tw-duration-100">
        <td class="tw-px-5 tw-py-3 tw-text-sm">{{ item.id }}</td>
        <td class="tw-px-5 tw-py-3 tw-text-sm">
            <a target="_blank" :href="'https://docs.google.com/spreadsheets/d/' + item.sheet_id" class="tw-flex tw-items-center tw-gap-2 tw-group">
                <span  class="tw-truncate tw-max-w-[150px]">{{ item.name }}</span>
                <icon icon="mynaui:external-link" class="tw-text-base group-hover:tw-text-primary-500 tw-text-gray-400 tw-duration-200" />
            </a>
        </td>
        <td class="tw-px-5 tw-py-3 tw-text-sm">
            <p class="tw-truncate">{{ item.sheet_name }}</p>
        </td>
        <td class="tw-px-5 tw-py-3 tw-text-sm">
            <p>{{ item.order_count }}</p>
        </td>
        <td class="tw-px-5 tw-py-3 tw-text-sm">
            <div class=" tw-text-xs">
                <p v-if="item.last_synced_at" class="tw-whitespace-nowrap">{{ lastSyncedAt }}</p>
                <p v-else class="tw-text-center">-</p>
            </div>
            
        </td>
        <td class="tw-px-5 tw-py-3 tw-text-sm">
            <template v-if="!item.has_errors">
                <p v-if="item.is_active" class="tw-text-emerald-500 tw-bg-emerald-100 tw-rounded tw-py-1 tw-px-2 tw-border tw-border-solid tw-border-emerald-200 tw-w-fit tw-text-xs">{{ $t('common.active') }}</p>
                <p v-if="!item.is_active" class="tw-text-amber-500 tw-bg-amber-100 tw-rounded tw-py-1 tw-px-2 tw-border tw-border-solid tw-border-amber-200 tw-w-fit tw-text-xs">{{ $t('common.inactive') }}</p>
            </template>
            <div v-else class="tw-flex tw-items-center tw-gap-2 tw-text-rose-500 tw-bg-rose-100 tw-rounded tw-py-1 tw-px-2 tw-border tw-border-solid tw-border-rose-200 tw-w-fit">
                <p class=" tw-text-xs tw-whitespace-nowrap">{{ $t('messages.there-is-problem') }}</p>
                <icon icon="solar:danger-circle-line-duotone" class="tw-text-xl" />

            </div>

        </td>
        <td class="tw-px-5 tw-pr-0 tw-py-">
            <SheetTableActions :item="item" />
        </td>
    </tr>
</template>

<script setup>
import { defineProps, toRef, computed } from 'vue';
import SheetTableActions from './SheetTableActions';
import moment from 'moment'
import { useI18n } from "vue-i18n";

const i18n = useI18n();
const props = defineProps(['item']);
const item = toRef(props, 'item');

const lastSyncedAt = computed(() => {
    return moment(item.value.last_synced_at).locale(i18n.locale.value).fromNow()
})

</script>

<style></style>