<template>
  <div>
    <div v-if="!fetched" class="tw-p-5 tw-bg-gray-100 tw-rounded tw-animate-pulse">

    </div>

    <div v-else class="tw-rounded">

      <div v-if="Object.keys(errors).length" class="tw-mb-5">
        <p v-for="e in errors" :key="e" class="tw-flex tw-gap-2 tw-text-rose-500">
          <span>*</span>
          <span>{{ $t('errors.' + e, e) }}</span>
        </p>
      </div>

      <div class="tw-flex tw-items-center tw-justify-center tw-flex-col tw-gap-5 tw-overflow-auto tw-w-full">
        <div v-for="item in order.items" :key="item.id + ' ' + item.updated_at"
          class="tw-w-full tw-border-solid tw-border-gray-200 last:tw-border-b-0 tw-pb-4 last:tw-pb-0 tw-shadow-sm tw-bg-white tw-p-4 tw-border tw-rounded">
          <div class="tw-w-full  tw-flex tw-items-start tw-gap-2 ">
            <div class="tw-flex-1 tw-min-w-[190px]">
              <div class="w-py-2">
                <form-vue-select hide-errors :label="$t('fields.product')" v-model="item.product_id"
                  @option:selected="() => item.product_variant_id = null" option-value="id" :options="products"
                  class="tw-bg-whitex">
                  <template v-slot:selected="{ option }">
                    <div v-if="option.id">
                      <p class="tw-max-w-[120px] tw-truncate tw-text-xs">{{ option.name }}</p>
                    </div>
                    <div v-else>
                      <p class="tw-max-w-[120px] tw-truncate tw-text-xs">{{ $t('common.none') }}</p>
                    </div>
                  </template>

                  <template v-slot:option="{ option }">
                    <div v-if="option.id">
                      <p class="tw-max-w-[120px] tw-truncate tw-text-xs">{{ option.name }}</p>
                    </div>
                    <div v-else>
                      <p class="tw-max-w-[120px] tw-truncate tw-text-xs">{{ $t('common.none') }}</p>
                    </div>
                  </template>
                </form-vue-select>

                <div class="tw-mt-2" v-if="getVariants(item.product_id).length > 1">
                  <form-vue-select left-icon="mdi:palette-swatch-variant" hide-label hide-errors
                    v-model="item.product_variant_id" option-value="id" :options="getVariants(item.product_id)"
                    class="tw-bg-whitex">
                    <template v-slot:selected="{ option }">
                      <div v-if="option.id">
                        <p class="tw-max-w-[120px] tw-truncate tw-text-xs">{{ option.variant_name }}</p>
                      </div>
                      <div v-else>
                        <p class="tw-max-w-[120px] tw-truncate tw-text-xs">{{ $t('common.none') }}</p>
                      </div>
                    </template>

                    <template v-slot:option="{ option }">
                      <div v-if="option.id">
                        <p class="tw-max-w-[120px] tw-truncate tw-text-xs">{{ option.variant_name }}</p>
                      </div>
                      <div v-else>
                        <p class="tw-max-w-[120px] tw-truncate tw-text-xs">{{ $t('common.none') }}</p>
                      </div>
                    </template>
                  </form-vue-select>
                </div>
              </div>
            </div>



            
          </div>

          <div class="tw-flex tw-gap-2 tw-mt-4">
            <div class="tw-flex-1">
              <FormField :label="$t('fields.quantity')" hide-errors type="number" v-model.number="item.quantity" min="0" />
            </div>

            <div class="tw-flex-1">
              <FormField :label="$t('fields.price')" hide-errors type="number" v-model.number="item.price" min="0"
                left-icon="mdi:currency-usd" />
            </div>
          </div>

          <div class="tw-mt-4">
            <div class="tw-w-full tw-px-1 tw-flex tw-items-center tw-justify-end tw-h-[40px] tw-gap-2">
              <a-button as="a" v-if="getSelectedProduct(item.product_id)?.video_url" :href="getSelectedProduct(item.product_id)?.video_url" target="_blank" left-icon="fluent:video-16-regular" class="!tw-text-gray-700 hover:!tw-text-gray-900 hover:!tw-border-none !tw-border-none hover:tw-bg-gray-100"
                intent="text"><span>{{ $t('fields.video-url') }}</span></a-button>
              <a-button as="a" v-if="getSelectedProduct(item.product_id)?.store_url" :href="getSelectedProduct(item.product_id)?.store_url" target="_blank" left-icon="raphael:page2" class="!tw-text-gray-700 hover:!tw-text-gray-900 hover:!tw-border-none !tw-border-none hover:tw-bg-gray-100"
                intent="text"><span>{{ $t('fields.store-url') }}</span></a-button>
              <a-button @click="item.visible = true" left-icon="solar:trash-bin-2-bold-duotone"
                intent="danger"><span>{{ $t('buttons.delete') }}</span></a-button>

              <DeleteModal :visible="item.visible" @confirm="onClickDeleteItem(item.id); item.visible = false"
                @cancel="item.visible = false" />
            </div>
          </div>

        </div>


        <div class=" tw-p-4 tw-w-full tw-flex tw-items-center tw-justify-center tw-bg-gray-100x">
          <a-button @click="onClickAddItem" intent="info" left-icon="oui:plus" class="">
            <p class="tw-whitespace-nowrap">{{ $t('buttons.click-to-add-item') }}</p>
          </a-button>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import { useStateStore } from '@/stores/app/state';
import { toRef, defineProps, defineEmits, computed } from 'vue';
import FormVueSelect from '@/components/form/FormVueSelect';
import FormField from '@/components/form/FormField';
import DeleteModal from '@/components/modals/DeleteModal';


// import { clone } from '@/utils/methods';

const props = defineProps(['order', 'errors']);
const emit = defineEmits(['update:order']); emit

const order = toRef(props, 'order');
const errors = computed(() => Object.fromEntries(
  Object.entries(props.errors)
    .filter(([key]) => key.startsWith('items'))
));

const stateStore = useStateStore();
const products = computed(() => [{ id: null }, ...stateStore.products.map(p => ({ ...p, label: p.name }))]);
const fetched = toRef(stateStore.fetched);


// const columns = ref([
//   { name: 'product', label: 'fields.product', classes: ['tw-w-[250px]'] },
//   // { name: 'variant', label: 'fields.variant_name', classes: ['tw-max-w-[30px]'] },
//   { name: 'quantity', label: 'fields.quantity', classes: ['tw-w-[30px]'] },
//   { name: 'actions', label: '', classes: ['tw-w-[30px]'] },
// ]);


const onClickAddItem = () => {
  console.log(props.order);
  console.log(order.value);
  emit('update:order', {
    ...order.value,
    items: [
      ...order.value.items,
      {
        id: Date.now(),
        product_id: null,
        product_name: '',
        product_variant_id: null,
        quantity: 1,
        price: 0,
        is_new: true
      },
    ],
  });

  console.log(props.order);
  console.log(order.value);
}

const onClickDeleteItem = (id) => {
  emit('update:order', {
    ...props.order,
    items: order.value.items.filter(i => i.id != id)
  });
}

const getVariants = id => {
  const product = products.value.find(p => p.id == id)

  if (product.variants?.length) {
    return [{ id: null }, ...product.variants]
  }
  return [{ id: null }]
}

const getSelectedProduct = id => {
  return products.value.find(p => p.id == id)
}
</script>

<style scoped></style>