<template>
    <div class="tw-p-2 tw-bg-white tw-w-full tw-border tw-border-solid tw-flex tw-items-center tw-gap-2">
        <router-link :to="{ name: 'pages.dashboard.agent' }" class="tw-text-gray-400 tw-duration-200 hover:tw-text-gray-700 tw-text-xl tw-flex tw-items-center tw-gap-2"><icon icon="solar:home-smile-bold-duotone" /> <span class="tw-text-sm">{{ $t("dashboard.sidebar.dashboard") }}</span></router-link>
        <span class="tw-text-gray-300">/</span>
        <button disabled class="tw-text-primary-500 tw-text-xl tw-flex tw-items-center tw-gap-2"><span class="tw-text-sm">{{ $t("dashboard.sidebar.confirm-orders") }}</span></button>
    </div>
    <div class="tw-p-2">
        <div class="tw-h-full tw-w-full tw-p-2 tw-bg-white tw-rounded tw-border tw-border-b-2 tw-min-h-[calc(100dvh-125px)] tw-flex tw-flex-col">
            <div class="tw-flex tw-justify-between tw-p-2">
                <div>
                    <h1 class="tw-text-xl">{{ $t('pages.confirm-orders.title') }}</h1>
                    <!-- <p class="tw-text-sm tw-text-gray-600">You can confirm orders with clients here</p> -->
                </div>

                <a-button @click="onRefresh" :loading="loading" :disabled="loading" intent="primary" left-icon="solar:add-circle-broken">{{ $t('buttons.refresh') }}</a-button>
            </div>

            <div v-if="!orders.length" class="tw-flex-1 tw-flex tw-items-center tw-justify-center tw-flex-col">
                <div class="tw-mx-auto tw-max-w-[300px] tw-p-5">
                    <img :src="$frontend('assets/images/undraw_join.svg')" class="tw-w-full tw-h-full" alt="">
                </div>
                <p class="md:tw-text-2xl tw-text-xl tw-text-center tw-font-semibold tw-mt-5 tw-text-gray-400">{{ $t('messages.no-orders-to-confirm') }}</p>
            </div>

            <div v-if="orders.length" class="tw-mt-2">
                <div class="tw-flex tw-flex-col tw-gap-5">
                    <OrderCard v-for="(order, index) in orders" :index="index" :key="order.id + order.updated_at" :order="order" />
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import { handle } from '@/api/orders';
import { ref, provide } from 'vue'
import { useI18n } from 'vue-i18n';
import OrderCard from './partials/OrderCard'
import useAlert from '@/composables/useAlert';

const loading = ref(false);
const orders = ref([]);
const i18n = useI18n();

const onRefresh = async () => {
    loading.value = true;
    await handle().then(response => {
        switch (response.data.code) {
            case 'NO_ORDERS':
                    useAlert(i18n.t('messages.no-orders-to-confirm'), 'info')
            break;

            case 'NOT_CONFIRMED':
                    useAlert(i18n.t('messages.orders-not-confirmed'), 'info');
                    handleFoundOrders(response.data.orders);
            break;

            case 'SUCCESS':
                handleFoundOrders(response.data.orders);
            break;
        
            default:
                break;
        }
        
    }).catch(error => {
        console.log(error)
    });
    loading.value = false;
}

const handleFoundOrders = (newOrders) => {
    orders.value = newOrders
}

const onUpdate = (order) => {
    orders.value = orders.value.map((item) => item.id == order.id ? order : item);

    if(order.agent_status != 'new') {
        orders.value = orders.value.filter(o => o.id != order.id)
    }
}

provide("updateOrder", onUpdate);

</script>

<style>

</style>