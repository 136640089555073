<template>
  <div class="tw-relative">
    <button
      @click="isActive = !isActive"
      ref="container"
      class="tw-relative active:tw-translate-y-0.5 active:tw-border-b active:tw-shadow-none tw-p-1.5 tw-border tw-border-b-2 tw-border-solid tw-rounded-lg hover:tw-bg-neutral-50/10 hover:tw-shadxow-md hover:tw-shadow-neutral-200 tw-duration-200"
    >
      <icon
        class="tw-text-2xl tw-text-neutral-600 tw-duration-200"
        icon="solar:bell-broken"
      />

      <span
        v-if="false"
        class="tw-absolute tw-top-1 tw-left-2 tw-animate-pulse tw-p-1 tw-bg-rose-500 tw-rounded-full"
      ></span>
    </button>

    <transition
      class="tw-duration-200"
      enter-from-class="tw-translate-y-[10px]  tw-opacity-0"
      leave-to-class="tw-translate-y-[10px]  tw-opacity-0"
    >
      <div v-if="isActive">
        <div
          @click="isActive = false"
          :class="[$root.$i18n.locale == 'ar' ? 'tw-left-0' : 'tw-right-0']"
          class="tw-w-[250px] tw-h-[200px] tw-absolute -tw-bottom-1 tw-translate-y-full tw-bg-white tw-rounded-lg tw-border tw-border-b-2"
        ></div>
      </div>
    </transition>
  </div>
</template>
  
  <script setup>
import { ref } from "vue";
import { onClickOutside } from "@vueuse/core";

const isActive = ref(false);
const container = ref(null);

onClickOutside(container, () => (isActive.value = false));
</script>
  
  <style>
</style>