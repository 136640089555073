import { getFromLocalStorage, saveInLocalStorage } from '@/utils/local-storage';
import { defineStore } from 'pinia';

const initialState = {
    count: {
        orders: getFromLocalStorage('count.orders', 0),
    },

    deliveries: getFromLocalStorage('deliveries', []),
    products: getFromLocalStorage('products', []),
    cities: getFromLocalStorage('cities', []),
    rates: getFromLocalStorage('rates', []),

    fetched: false,
}

export const useStateStore = defineStore('stateStore', {
    state: () => initialState,

    getters: {
        alldeliveries: state => [{ value: null, label: 'None', id: null, name: 'None' }, ...state.deliveries],
    },

    actions: {
        setCountOrders(value) {
            this.count.orders = value;
            saveInLocalStorage('count.orders', value);
        },

        setCount(value) {
            this.count = value;
            saveInLocalStorage('count', value);
        },
        
        setDeliveries(value) {
            this.deliveries = value;
            saveInLocalStorage('deliveries', value);
        },
        
        setProducts(value) {
            this.products = value;
            saveInLocalStorage('products', value);
        },

        setCities(value) {
            this.cities = value;
            saveInLocalStorage('cities', value);
        },

        setRates(value) {
            this.rates = value;
            saveInLocalStorage('rates', value);
        },

        setFetched(value) {
            this.fetched = value;
        }

    }
});