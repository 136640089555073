<template>
    <div>
        <a-button @click="visible = true" intent="warning" left-icon="solar:pen-bold-duotone" class="tw-min-w-[100px]">
            {{ $t("buttons.edit") }}
        </a-button>

        <transition>
            <div v-if="container">
                <update-order-popup v-model:visible="visible" :item="props.item" @update="newOrder => updateOrder(newOrder)" />
            </div>
        </transition>
    </div>
</template>

<script setup>
import { ref, watch, defineProps, inject } from 'vue';
import UpdateOrderPopup from '@/components/orders/UpdateOrderPopup';

const updateOrder = inject('updateOrder')
const visible = ref(false);
const container = ref(false);
const props = defineProps({
    item: {
        required: true
    }
})

watch(() => visible.value, (newValue) => {

    if (!newValue) {
        setTimeout(() => container.value = false, 300)
    }

    if (newValue) {
        container.value = true
    }
});
</script>

<style></style>