import Cookie from 'js-cookie';
import axios from 'axios';
import { BACKEND_URL } from '@/config/app';

export default {
    async getCookie() {

        const endpoint = BACKEND_URL +  "sanctum/csrf-cookie";
        const csrf = Cookie.get("XSRF-TOKEN");
        
        if(csrf) return new Promise(resolve => resolve(csrf))
        
        return await axios.get(endpoint, {withCredentials: true});
    }
};