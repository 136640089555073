import { saveInLocalStorage } from '@/utils/local-storage';
import { defineStore } from 'pinia';

const initialState = {
    users: [],
    fetched: false,
}

export const useUserStore = defineStore('userStore', {
    state: () => initialState,

    getters: {},

    actions: {
        setUsers(value) {
            this.users = value;
            saveInLocalStorage('users', value);
        },

        setFetched(value) {
            this.fetched = value;
        }

    }
});