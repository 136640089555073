import {
    login
} from "@/api/auth";
import {
    useAuthStore
} from "@/stores";
import {
    reactive,
    ref
} from "vue";
import useAlert from "@/composables/useAlert";
import {
    useI18n
} from "vue-i18n";
import {
    useRouter
} from "vue-router";


export default (user) => {

    const i18n = useI18n();
    const router = useRouter();
    const store = useAuthStore();
    const isFetching = ref(false);
    const errors = reactive({
        email: '',
        password: ''
    });

    const success = async (response) => {

        if (response.data.code != 'SUCCESS') {
            return false;
        }

        const user = response.data.data.user;
        store.setUser(user, true);
        router.push({
            name: 'dashboard'
        })
    }


    const failed = async (error) => {

        if (!error.response) return false;

        if (error.response.status == 422) {
            for (let e in error.response.data.errors) {
                errors[e] = error.response.data.errors[e][0];
            }
        }

        if (error.response.status == 401) {
            errors.email = 'invalid-credentials';
        }

        if (error.response.status == 429) {
            useAlert(i18n.t('errors.login-throttle-limit'), 'warning');
        }

        return false;
    }



    const execute = async () => {
        isFetching.value = true;
        await login(user)
            .then(success, failed)
        isFetching.value = false;
    }


    return {
        execute,
        isFetching,
        errors
    }

}