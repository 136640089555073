import {
    server
} from "@/api";


export const create = async (order) => {
    return await server().post('api/orders', order)
}

export const paginate = async (params) => {
    return await server().get('api/orders', { params });
}

export const products = async (params) => {
    return await server().get('api/products/for-orders', { params });
}

export const update = async (id, order) => {
    return await server().post(`api/orders/${id}`, order);
}

export const handle = async () => {
    return await server().get(`api/orders/handle`);
}

export default {
    create,
    paginate,
    update,
    products
}