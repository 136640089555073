<template>
    <div>
        <FormVueSelect :searchable="false" v-model="order.delivery_status" max-height="200" :options="deliveries" option-value="value"
            :label="$t('fields.delivery_status')" :error="errors.delivery_status &&
                $t('errors.' + errors.delivery_status, {
                    field: $t('fields.delivery_status'),
                })
                ">
            <template v-slot:option="{ option }">
                <p :class="[option.color]" class="tw-p-2 tw-rounded tw-text-xs tw-px-4">{{
                    $t(option.name) }}</p>
            </template>
        </FormVueSelect>
    </div>
</template>

<script setup>
import FormVueSelect from "@/components/form/FormVueSelect";
import { order_deliveries } from '@/config/status';
import { defineProps, defineEmits, computed, toRef } from 'vue';
import { useI18n } from "vue-i18n";

const i18n = useI18n();

const deliveries = computed(() => order_deliveries.map(c => ({ ...c, label: i18n.t(c.name) })))
const props = defineProps(['order', 'errors'])
const emit = defineEmits(['update:order'])
const errors = toRef(props, 'errors')

const order = computed({
    get() {
        return props.order
    },
    set(value) {
        emit('update:order', value)
    }
})
</script>

<style></style>