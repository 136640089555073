<template>
    <a-popup :visible="visible" @cancel="onCancel">
        <div
            class="tw-mx-auto tw-w-[95%] md:tw-max-w-[800px]  tw-h-fit tw-overflow-auto tw-flex tw-flex-col tw-border tw-border-solid dark:tw-border-gray-400 tw-border-gray-300 tw-shadow-md tw-my-16 tw-rounded">
            <div>
                <div
                    class="tw-p-4 tw-text-lg tw-flex tw-items-center tw-gap-2 tw-border-b tw-border-solid tw-font-semibold dark:tw-text-gray-700 tw-text-gray-500 dark:tw-bg-gray-100 tw-bg-gray-50">
                    <span>{{ $t("common.order") }}</span>
                    <span class="tw-text-primary-500">{{ order.id }}</span>
                </div>

                <div class="tw-p-5 tw-bg-white tw-flex-1">
                    <div class="tw-grid tw-grid-cols-12 tw-gap-x-4 tw-gap-y-0">

                        <div v-if="order.nawris_code" class="tw-col-span-12 md:tw-col-span-6">
                            <FormField v-model="order.nawris_code" readonly :label="$t('fields.tracking_code')" type="text"
                                :error="errors.nawris_code &&
                                    $t('errors.' + errors.nawris_code, {
                                        field: $t('fields.nawris_code'),
                                    })
                                    " />
                        </div>

                        <div class="tw-col-span-12 md:tw-col-span-12 tw-pb-4">
                            <h1 class="tw-ps-4 tw-border-s-2 tw-border-solid tw-border-primary-500">{{
                                $t('common.customer_infromations') }}</h1>
                        </div>

                        <div class="tw-col-span-12 md:tw-col-span-6">
                            <FormField v-model="order.customer_name" :label="$t('fields.customer_name')" type="text"
                                :error="errors.customer_name &&
                                    $t('errors.' + errors.customer_name, {
                                        field: $t('fields.customer_name'),
                                    })
                                    " />
                        </div>

                        <div class="tw-col-span-12 md:tw-col-span-6">
                            <FormField v-model="order.customer_phone" :label="$t('fields.customer_phone')" type="text"
                                :error="errors.customer_phone &&
                                    $t('errors.' + errors.customer_phone, {
                                        field: $t('fields.customer_phone'),
                                    })
                                    " />
                        </div>

                        <div v-if="false" class="tw-col-span-12 md:tw-col-span-6">
                            <FormField  v-model="order.customer_city" :label="$t('fields.customer_city')" type="text"
                                :error="errors.customer_city &&
                                    $t('errors.' + errors.customer_city, {
                                        field: $t('fields.customer_city'),
                                    })
                                    " />
                        </div>

                        <div class="tw-col-span-12 md:tw-col-span-6">
                            <FormVueSelect v-model="order.customer_city" max-height="200" option-label="name" option-value="name" :options="cities"
                                :label="$t('fields.customer_city')" type="text" :error="errors.customer_city &&
                                    $t('errors.' + errors.customer_city, {
                                        field: $t('fields.customer_city'),
                                    })
                                    ">
                                <template v-slot:option="{ option }">
                                    <div>
                                        <p class="tw-max-w-[135px] tw-truncate tw-text-xs">{{ option.name }}</p>
                                    </div>
                                </template>
                            </FormVueSelect>
                        </div>

                        <div v-if="areas.length" class="tw-col-span-12 md:tw-col-span-6">
                            <FormVueSelect v-model="order.customer_area" max-height="200" option-label="name" option-value="name" :options="areas"
                                :label="$t('fields.customer_area')" type="text" :error="errors.customer_area &&
                                    $t('errors.' + errors.customer_area, {
                                        field: $t('fields.customer_area'),
                                    })
                                    ">
                                <template v-slot:option="{ option }">
                                    <div>
                                        <p class="tw-max-w-[135px] tw-truncate tw-text-xs">{{ option.name }}</p>
                                    </div>
                                </template>
                            </FormVueSelect>
                        </div>

                        <div :class="[areas.length && 'md:!tw-col-span-12']" class="tw-col-span-12 md:tw-col-span-6">
                            <FormField v-model="order.customer_address" :label="$t('fields.customer_address')"
                                type="text" :error="errors.customer_address &&
                                    $t('errors.' + errors.customer_address, {
                                        field: $t('fields.customer_address'),
                                    })
                                    " />
                        </div>

                        <div class="tw-col-span-12 md:tw-col-span-12">
                            <OrderConfirmation v-model:order="order" :errors="errors" />
                        </div>

                        <div v-if="order.agent_status == 'no-answer'" class="tw-col-span-12 md:tw-col-span-12">
                            <div class="tw-relative tw-flex tw-items-center tw-gap-1 tw-w-full" >

                                <button @click="order.calls++" :class="$root.$i18n.locale == 'ar' ? 'tw-left-0' : 'tw-right-0'" class="tw-h-[40px] tw-w-[60px] tw-bg-cyan-500 tw-text-white tw-absolute tw-z-10 tw-rounded-s-none tw-translate-y-[1.5px]  tw-rounded tw-flex tw-items-center tw-justify-center tw-border-solid tw-border tw-border-cyan-700">
                                    <icon icon="heroicons-outline:plus" />
                                </button>

                                <button @click="order.calls <= 0 ? order.calls = 0 : order.calls--" :class="$root.$i18n.locale == 'ar' ? 'tw-right-0' : 'tw-left-0'" class="tw-h-[40px] tw-w-[60px] tw-bg-cyan-500 tw-text-white tw-absolute tw-z-10 tw-rounded-e-none tw-translate-y-[1.5px]  tw-rounded tw-flex tw-items-center tw-justify-center tw-border-solid tw-border tw-border-cyan-700">
                                    <icon icon="heroicons-outline:minus" />
                                </button>

                                <FormField class="tw-w-full" input-class="tw-text-center tw-text-cyan-500 tw-font-bold" left-icon="heroicons-outline:plus" right-icon="heroicons-outline:minus" v-model,numbder="order.calls" :label="$t('fields.calls')"
                                type="number" :error="errors.calls &&
                                    $t('errors.' + errors.calls, {
                                        field: $t('fields.calls'),
                                    })
                                " />
                            </div>
                        </div>

                        <div class="tw-col-span-12 md:tw-col-span-12">
                            <OrderAffectation v-model:order="order" :errors="errors" />
                        </div>

                        <div v-if="$user.role == 'admin'" class="tw-col-span-12 md:tw-col-span-12">
                            <OrderDelivery v-model:order="order" :errors="errors" />
                        </div>



                        <div class="tw-col-span-12 md:tw-col-span-12">
                            <FormField v-model="order.agent_notes" :label="$t('fields.agent_notes')" type="textarea"
                                :error="errors.agent_notes &&
                                    $t('errors.' + errors.agent_notes, {
                                        field: $t('fields.agent_notes'),
                                    })
                                    " />
                        </div>

                        <div class="tw-col-span-12 md:tw-col-span-12 tw-pb-4">
                            <h1 class="tw-ps-4 tw-border-s-2 tw-border-solid tw-border-primary-500">{{
                                $t('common.order_summary') }}</h1>
                        </div>

                        <div class="tw-col-span-12 md:tw-col-span-12">
                            <OrderSummary v-model:order="order" :errors="errors" />
                        </div>

                    </div>
                </div>

                <div
                    class="tw-p-4 tw-text-lg tw-border-t tw-justify-end tw-border-solid tw-font-medium tw-flex dark:tw-text-gray-700 tw-text-gray-500 tw-items-center tw-gap-4 dark:tw-bg-gray-100 tw-bg-gray-50">
                    <a-button :disabled="loading" @click="onCancel" class="tw-min-w-[120px]" intent="simple">{{
                        $t("buttons.cancel") }}</a-button>
                    <a-button :disabled="loading" :loading="loading" @click="onUpdate" class="tw-min-w-[120px]">{{
                        $t("buttons.update") }}</a-button>
                </div>
            </div>
        </div>
    </a-popup>
</template>

<script setup>
import { ref, defineProps, defineEmits, watch, computed } from 'vue';
import { clone } from '@/utils/methods';
import { update } from '@/api/orders';
import { useI18n } from 'vue-i18n';
import useAlert from '@/composables/useAlert';
import FormField from "@/components/form/FormField";
import FormVueSelect from "@/components/form/FormVueSelect";
import OrderConfirmation from '@/components/orders/partials/OrderConfirmation';
import OrderDelivery from '@/components/orders/partials/OrderDelivery';
import OrderAffectation from '@/components/orders/partials/OrderAffectation';
import OrderSummary from '@/components/orders/partials/OrderSummary';
import { useStateStore } from '@/stores/app/state';

const i18n = useI18n()
const stateStore = useStateStore();

const emit = defineEmits(['update:visible', 'update']);
const props = defineProps({
    item: {
        required: true
    },
    visible: {
        required: true,
        default: false
    }
});

const order = ref(clone(props.item));
const cities = computed(() => stateStore.cities)
const areas = computed(() => stateStore.cities.find(c => c.name === order.value.customer_city)?.areas ?? [])
const originalOrder = ref(clone(props.item));
const errors = ref({})
const changed = ref([])
const loading = ref(false)

watch(() => props.item, (newValue) => {
    order.value = clone(newValue)
}, { deep: true })

watch(() => order.value.customer_city, () => {
        order.value.customer_area = null;
})


const onUpdate = async () => {
    const fields = getChangedFields()
    const data = { ...order.value, fields }

    loading.value = true
    await update(props.item.id, data)
        .then(
            res => {
                console.log(res)
                if (res.data.code == 'SUCCESS') {
                    emit('update:visible', false)
                    emit('update', res.data.order)
                    useAlert(i18n.t('messages.updated-successfully', { field: i18n.t('common.order') }))
                }
            },

            (err) => {
                errors.value = {}
                
                if (err.response.status == 422) {
                    for (let e in err.response.data.errors) {
                        errors.value[e] = err.response.data.errors[e][0];
                    }
                }
            }   
        )
    loading.value = false
}

const onCancel = () => {
    emit('update:visible', false)
}

const getChangedFields = () => {
    changed.value = []
    for (const key in order.value) {
        if (order.value[key] !== originalOrder.value[key]) {
            changed.value.push(key)
        }
    }

    return changed.value
}


</script>

<style></style>