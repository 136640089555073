<template>
    <tr :key="item.id"
        class="tw-border-b tw-border-solid tw-border-gray-100 last:tw-border-b-0 tw-group even:tw-bg-gray-50/50 hover:tw-bg-primary-50/60 tw-duration-100">
        <td class="tw-px-5 tw-py-3 tw-text-sm">{{ item.id }}</td>
        <td class="tw-px-5 tw-py-3 tw-text-sm">
            <p class="tw-truncate">{{ item.customer_name }}</p>
        </td>
        <td class="tw-px-5 tw-py-3 tw-text-sm">
            <p class="tw-truncate tw-max-w-[100px]">{{ item.customer_phone }}</p>
        </td>

        <td class="tw-px-5 tw-py-3 tw-text-sm">
            <p class="tw-truncate tw-max-w-[70px]">{{ item.customer_city }}</p>
        </td>

        <td class="tw-px-5 tw-py-3 tw-text-sm">
            <div class="tw-flex tw-flex-col tw-gap-1 tw-text-sm">
                <div v-for="i in item.items" :key="i.id + i.updated_at" class="tw-flex tw-items-center tw-gap-2">
                    <span>{{ i.quantity }}</span>
                    <span class="tw-font-bold tw-text-primary-500">x</span>
                    <p class="tw-max-w-[120px] tw-truncate">{{ i.product_name }}</p>
                    <p v-if="i.variant_name" class="tw-max-w-[50px] tw-truncate tw-text-emerald-500 tw-font-bold">({{ i.variant_name }})</p>
                </div>

            </div>
        </td>

        <td class="tw-px-5 tw-py-3 tw-text-sm">
            <p class="tw-truncate tw-max-w-[70px] tw-font-bold tw-flex tw-items-center tw-gap-1">
                <span>{{ item.items.reduce((p, c) => p + parseFloat(c.price), 0).toFixed(2) }}</span>
                <span>{{ $t('currencies.' + CURRENCY, 'USD') }}</span>
            </p>
        </td>

        <td class="tw-px-5 tw-py-3 tw-text-sm">
            <p class="tw-truncate tw-max-w-[70px] tw-font-bold tw-flex tw-items-center tw-gap-1 tw-px-2 tw-text-cyan-500">
                {{ item.calls }}
            </p>
        </td>

        <td class="tw-px-5 tw-py-3 tw-text-sm">
            <p class="tw-truncate tw-max-w-[100px] tw-text-xs tw-rounded tw-w-fit tw-px-4 tw-py-1 tw-mx-auto " :class="[confirmation.color]" v-if="confirmation?.name">
                {{ $t(confirmation.name) }}
            </p>
        </td>

        <td class="tw-px-5 tw-py-3 tw-text-sm">
            <div v-if="item.delivery_id" class="tw-flex tw-items-center tw-gap-2 tw-mx-auto tw-justify-center">
                
                <div class="tw-flex tw-items-center tw-justify-center tw-gap-2 tw-w-fit  tw-p-1  tw-bg-gray-100 tw-px-4 tw-rounded-md tw-border tw-border-solid tw-border-gray-200 hover:tw-border-gray-400 tw-duration-200 tw-cursor-pointer">
                    <p  class="tw-truncate tw-max-w-[100px] tw-font-bold tw-text-xs tw-rounded tw-w-fit">
                        {{ delivery.name }}
                    </p>
                </div>
                <a v-if="item.nawris_code" target="_blank" :href="getOrderLink(item.nawris_code)">
                    <icon icon="mynaui:external-link" class="tw-text-base group-hover:tw-text-primary-500 tw-text-gray-400 tw-duration-200" />
                </a>
            </div>
            <p v-else class="tw-truncate tw-max-w-[100px] tw-text-xs tw-rounded tw-w-fit tw-px-4 tw-py-1 tw-mx-auto">
                {{ $t('common.without') }}
            </p>
        </td>

        <td class="tw-px-5 tw-py-3 tw-text-sm">
            <p class="tw-truncate tw-max-w-[100px] tw-text-xs tw-rounded tw-w-fit tw-px-4 tw-py-1 tw-mx-auto" :class="[delivery_status.color]" v-if="delivery_status?.name">
                {{ $t(delivery_status.name) }}
            </p>
        </td>

        <td class="tw-px-5">
            <OrderTableActions :item="item" />
        </td>
    </tr>
</template>

<script setup>
import { defineProps, toRef } from "vue";
import OrderTableActions from "./OrderTableActions";
import { getConfirmation, getDelivery } from "@/utils/status";
import { useStateStore } from "@/stores/app/state";
import { getOrderLink } from '@/utils/nawris'
import { CURRENCY } from "@/config/app";


const props = defineProps(["item"]);
const item = toRef(props, "item");
const stateStore = useStateStore();

const confirmation = getConfirmation(item.value.agent_status)
const delivery_status = getDelivery(item.value.delivery_status)

const delivery = stateStore.alldeliveries.find(d => d.id == item.value.delivery_id)
</script>

<style></style>
