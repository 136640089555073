import AppLayout from '@/layouts/app/AppLayout'
import LoginView from '@/views/auth/LoginView'
import RegisterView from '@/views/auth/RegisterView'
import EmailConfirmationView from '@/views/auth/EmailConfirmationView';

export default [
    {
        path: '/auth',
        component: AppLayout,
        meta: {
            middleware: [ 'guest' ]
        },
        children: [
            {
                name: 'auth.login',
                path: 'login',
                component: LoginView
            },
            {
                name: 'auth.register',
                path: 'register',
                component: RegisterView
            },
            {
                name: 'auth.email-confirmation',
                path: 'email-confirmation',
                component: EmailConfirmationView
            },
        ]
        
    }
]