<template>
  <div class="tw-rounded tw-overflow-auto tw-border tw-border-solid tw-border-gray-100">
    <table class="tw-min-w-full tw-leading-normal">
      <thead class="">
        <tr>
          <th v-for="c in columns" :key="c.name"
            :class="c.classes"
            class="tw-px-5 last:tw-border-e-0 tw-whitespace-nowrap tw-border-e tw-py-3 tw-border-b tw-borderx tw-border-gray-100 tw-bg-gray-50 tw-text-start tw-text-[12px] tw-font-semibold tw-text-gray-600 tw-uppercase tw-tracking-wider"
          >
            {{ $t(c.label) }}
          </th>
        </tr>
      </thead>
  
      <tbody v-if="props.loading">
        <tr class="tw-border-b tw-border-solid tw-border-gray-100 last:tw-border-b-0 tw-group even:tw-bg-gray-50/50" v-for="i in 10" :key="i">
          <td v-for="c in columns" :key="c.name" class="tw-px-2 tw-py-3">
            <div class="tw-w-full tw-py-3 tw-bg-gray-50 group-even:tw-bg-white  tw-rounded tw-min-w-[30px] tw-animate-pulse">

            </div>
          </td>
        </tr>
      </tbody>


      <tbody v-else>
        <template v-if="items.length > 0">
          <ProductTableRow v-for="item in items" :key="item.id" :item="item" />
        </template>
        <template v-else>
          <tr>
            <td :colspan="columns.length">
              <div class="tw-p-5 tw-bg-white tw-min-h-[250px]  tw-text-gray-600 tw-flex tw-items-center tw-justify-center tw-text-lg">
                <p>{{ $t('messages.table-no-results') }}</p>
              </div>
            </td>
          </tr>
        </template>
      </tbody>
    </table>

    </div>

    <div class="tw-my-4"></div>

    <TablePagination v-if="items.length > 0" />
</template>

<script setup>
import { ref , defineProps, toRef} from 'vue'
import TablePagination from '@/components/table/TablePagination'
import ProductTableRow from './ProductTableRow'

const props = defineProps(['loading', 'data', 'items']);
const items = toRef(props, 'items')

const columns = ref([
  { name: 'id', label: 'fields.id', classes: [ 'tw-w-[30px]' ] },
  { name: 'sku', label: 'fields.product-sku', classes: [ 'tw-w-[120px]' ] },
  { name: 'name', label: 'fields.name', classes: [ 'tw-truncate' ] },
  { name: 'quantity', label: 'fields.quantity', classes: [ 'tw-w-[50px]' ] },
  { name: 'actions', label: 'fields.actions', classes: [ 'tw-w-[100px]' ] },
])
</script>

<style></style>
