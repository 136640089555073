<template>
  <div class="tw-h-full">
    <div class="tw-w-full tw-h-full">
      <div class="tw-w-full tw-my-5 tw-px-5">
        <ul class="tw-flex tw-gap-5 tw-border-b">
          <li 
          v-for="t in tabs" :key="t.key" @click="tab = t"
          :class="[t.key == tab.key ? '!tw-border-b-2 !tw-border-primary-500' : 'tw-border-b-2 tw-border-transparent']"
            class="tw-text-sm tw-pb-2 tw-font-semibold tw-border-b-2 tw-border-transparent tw-w-fit tw-duration-200 tw-cursor-pointer hover:tw-text-gray-600"
          >
            {{ $t(t.name) }}
          </li>
        </ul>
      </div>
    </div>

    <div class="tw-w-full tw-overflow-hidden">
      <transition
        class="tw-duration-200"
        enter-from-class="tw-opacity-0 tw-translate-x-[10px]"
        leave-to-class="tw-opacity-0 -tw-translate-x-[10px]"
        mode="out-in"
      >
        <component :is="tab.component" />
      </transition>
    </div>
  </div>
</template>

<script setup>
import AnalyticsTab from "./tabs/AnalyticsTab";
import OverviewTab from "./tabs/OverviewTab";
import UsersTab from "./tabs/UsersTab";
import { shallowRef } from "vue";

const tabs = [
    {
      key: "overview-tab",
      component: OverviewTab,
      name: "pages.dashboard.overview",
      icon: "ic:round-warehouse",
    },
  {
    key: "analytics-tab",
    component: AnalyticsTab,
    name: "pages.dashboard.analytics",
    icon: "clarity:help-info-solid",
  },
  {
    key: "users-tab",
    component: UsersTab,
    name: "pages.dashboard.users",
    icon: "dashicons:admin-links",
  },
];

const tab = shallowRef(tabs[0]);
</script>

<style></style>
