import {
    server
} from "@/api";


export const initial = async () => {
    return await server().get('api/app/initial')
}

export default {
    initial
}