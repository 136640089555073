<template>
    <div class="tw-grid tw-grid-cols-12 tw-gap-2 tw-mb-2">
        <div class="tw-p-5 tw-col-span-12 md:tw-col-span-6 lg:tw-col-span-3  tw-border tw-border-b-2 tw-rounded-md tw-bg-white">
          <div class="tw-flex tw-items-center tw-gap-2">
            <icon class="tw-text-2xl tw-text-emerald-500" icon="solar:bag-5-bold-duotone" />
            <span class="tw-text-md tw-font-semibold tw-text-neutral-700">
              {{ $t("pages.dashboard.total-orders") }}
            </span>
          </div>
          <div :class="[$root.$i18n.locale == 'ar' ? 'tw-pr-5' : 'tw-pl-5']" class="tw-flex tw-justify-between tw-items-center tw-gap-5 tw-flex-wrap tw-mt-3">
            <div v-if="!stateStore.fetched" class="tw-text-black tw-text-2xl tw-font-bold tw-h-[45px] tw-flex tw-items-center">
              <icon icon="eos-icons:three-dots-loading" class="md:tw-text-4xl tw-text-2xl tw-text-gray-500" />
            </div>
            <div v-else class="tw-text-black tw-text-2xl tw-font-bold tw-h-[45px] tw-flex tw-items-center">{{ stateStore.count.orders }}</div>
            
            <span v-if="false" class="tw-flex tw-items-center tw-gap-0.5 tw-text-emerald-400 tw-text-sm tw-font-semibold">
              <span>+</span>
              <span>50.35</span>
              <span>%</span>
            </span>
          </div>
        </div>

        <div v-if="false" class="tw-p-5 tw-col-span-12 md:tw-col-span-6 lg:tw-col-span-3  tw-border tw-border-b-2 tw-rounded-md tw-bg-white">
          <div class="tw-flex tw-items-center tw-gap-2">
            <icon class="tw-text-2xl tw-text-amber-500" icon="solar:dollar-minimalistic-bold-duotone" />
            <span class="tw-text-md tw-font-semibold tw-text-neutral-700">
              {{ $t("pages.dashboard.earnings") }}
            </span>
          </div>
          <div :class="[$root.$i18n.locale == 'ar' ? 'tw-pr-5' : 'tw-pl-5']" class="tw-flex tw-justify-between tw-items-center tw-gap-5 tw-flex-wrap tw-mt-3">
            <span class="tw-text-black tw-text-2xl tw-font-bold">
              <span class="tw-text-emerald-500">$</span>12.5K</span>

          </div>
        </div>

        <div class="tw-p-5 tw-col-span-12 md:tw-col-span-6 lg:tw-col-span-3  tw-border tw-border-b-2 tw-rounded-md tw-bg-white">
          <div class="tw-flex tw-items-center tw-gap-2">
            <icon class="tw-text-2xl tw-text-blue-500" icon="solar:verified-check-bold-duotone" />
            <span class="tw-text-md tw-font-semibold tw-text-neutral-700">
              {{ $t("pages.dashboard.confirmed") }}
            </span>
          </div>
          <div :class="[$root.$i18n.locale == 'ar' ? 'tw-pr-5' : 'tw-pl-5']" class="tw-flex tw-justify-between tw-items-center tw-gap-5 tw-flex-wrap tw-mt-3">
            <div v-if="!stateStore.fetched" class="tw-text-black tw-text-2xl tw-font-bold tw-h-[45px] tw-flex tw-items-center">
              <icon icon="eos-icons:three-dots-loading" class="md:tw-text-4xl tw-text-2xl tw-text-gray-500" />
            </div>
            <div v-else class="tw-text-black tw-text-2xl tw-font-bold tw-h-[45px] tw-flex tw-items-center">{{ stateStore.count.confirmed }}</div>
            <span  class="tw-flex tw-items-center tw-gap-0.5 tw-text-emerald-400 tw-text-base tw-font-semibold">
              <span>{{ stateStore.rates.confirmed }}</span>
              <span>%</span>
            </span>
          </div>
        </div>

        <div class="tw-p-5 tw-col-span-12 md:tw-col-span-6 lg:tw-col-span-3  tw-border tw-border-b-2 tw-rounded-md tw-bg-white">
          <div class="tw-flex tw-items-center tw-gap-2">
            <icon class="tw-text-2xl tw-text-rose-500" icon="solar:call-cancel-bold-duotone" />
            <span class="tw-text-md tw-font-semibold tw-text-neutral-700">
              {{ $t("pages.dashboard.cancelled") }}
            </span>
          </div>
          <div :class="[$root.$i18n.locale == 'ar' ? 'tw-pr-5' : 'tw-pl-5']" class="tw-flex tw-justify-between tw-items-center tw-gap-5 tw-flex-wrap tw-mt-3">
            <div v-if="!stateStore.fetched" class="tw-text-black tw-text-2xl tw-font-bold tw-h-[45px] tw-flex tw-items-center">
              <icon icon="eos-icons:three-dots-loading" class="md:tw-text-4xl tw-text-2xl tw-text-gray-500" />
            </div>
            <div v-else class="tw-text-black tw-text-2xl tw-font-bold tw-h-[45px] tw-flex tw-items-center">{{ stateStore.count.canceled }}</div>
          </div>
        </div>

      </div>
    
</template>

<script setup>
import { useStateStore } from '@/stores/app/state';

const stateStore = useStateStore();


</script>

<style>

</style>