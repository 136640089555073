<template>
    <div>
        <FormVueSelect :searchable="false" v-model="order.delivery_id" max-height="200" :options="deliveries" option-value="id"
            :label="$t('fields.delivery_id')" :error="errors.delivery_id &&
                $t('errors.' + errors.delivery_id, {
                    field: $t('fields.delivery_id'),
                })
                ">
            <template v-slot:option="{ option }">
                <p class="tw-p-2 tw-rounded tw-text-xs tw-px-4">{{
                    option.label }}</p>
            </template>
        </FormVueSelect>
    </div>
</template>

<script setup>
import FormVueSelect from "@/components/form/FormVueSelect";
import { useStateStore } from "@/stores/app/state";
import { defineProps, defineEmits, computed, toRef } from 'vue';
import { useI18n } from "vue-i18n";

const stateStore = useStateStore();
const i18n = useI18n()

const deliveries = computed(() => stateStore.alldeliveries.map(d => ({ ...d, label: d.id ? d.name : i18n.t('common.without') })))

const props = defineProps(['order', 'errors'])
const emit = defineEmits(['update:order'])
const errors = toRef(props, 'errors')

const order = computed({
    get() {
        return props.order
    },
    set(value) {
        emit('update:order', value)
    }
})
</script>

<style></style>