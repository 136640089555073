<template>
    <div>
        <a-action-button @click="visible = true" icon="solar:pen-bold-duotone" intent="warning"></a-action-button>

        <transition>
            <div v-if="container">
                <update-order-popup v-model:visible="visible" :item="props.item" @update="order => updateOrder(order)" />
            </div>
        </transition>
    </div>
</template>

<script setup>
import { ref, watch, defineProps, inject } from 'vue';
import UpdateOrderPopup from '@/components/orders/UpdateOrderPopup';

const updateOrder = inject('updateOrder')
const visible = ref(false);
const container = ref(false);
const props = defineProps({
    item: {
        required: true
    }
})

watch(() => visible.value, (newValue) => {

    if (!newValue) {
        setTimeout(() => container.value = false, 300)
    }

    if (newValue) {
        container.value = true
    }
});

</script>

<style></style>