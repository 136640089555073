import UserListView from "@/views/admin/users/UserListView";
import UserEditView from "@/views/admin/users/UserEditView";
import UserCreateView from "@/views/admin/users/UserCreateView";
import UserDetailView from "@/views/admin/users/UserDetailView";

export default [
  {
    name: "pages.admin.users",
    path: "users",
    component: UserListView
  },
  {
    name: "pages.admin.users.create",
    path: "users/create",
    component: UserCreateView
  },
  {
    name: "pages.admin.users.show",
    path: "users/:id",
    component: UserDetailView
  },
  {
    name: "pages.admin.users.edit",
    path: "users/:id/edit",
    component: UserEditView
  },
  
];
