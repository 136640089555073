<template>
    <div>
        <a-button @click="visible = true" class="tw-hidden md:tw-flex" left-icon="heroicons:plus-16-solid">{{
            $t("buttons.create") }}</a-button>
        <a-button @click="visible = true" class="md:tw-hidden" icon="heroicons:plus-16-solid"></a-button>
        <a-popup :visible="visible" @cancel="onCancel">
            <div
                class="tw-mx-auto tw-w-[95%] md:tw-max-w-[800px] tw-overflow-hidden tw-h-fit dark:tw-bg-gray-900 tw-bg-white tw-border tw-border-solid dark:tw-border-gray-400 tw-border-gray-300 tw-shadow-md tw-my-5 tw-rounded">
                <div
                    class="tw-p-4 tw-text-lg tw-border-b tw-border-solid tw-font-medium tw-flex dark:tw-text-gray-700 tw-text-gray-500 tw-items-center tw-gap-4 dark:tw-bg-gray-100 tw-bg-gray-50">
                    {{ $t("titles.create-new-item") }}
                </div>

                <div class="tw-p-5 tw-bg-white">
                    <div class="tw-grid tw-grid-cols-12 tw-gap-x-4 tw-gap-y-0">
                        <div class="tw-col-span-12 md:tw-col-span-12">
                            <FormField v-model="sheet.name" :label="$t('fields.name')" type="text" :error="errors.name &&
                                $t('errors.' + errors.name, {
                                    field: $t('fields.name'),
                                })
                                " />
                        </div>

                        <div class="tw-col-span-12 md:tw-col-span-12">
                            <FormField v-model="sheet.sheet_id" :label="$t('fields.sheet_id')" type="text" :error="errors.sheet_id &&
                                $t('errors.' + errors.sheet_id, {
                                    field: $t('fields.sheet_id'),
                                })
                                " />
                        </div>

                        <div class="tw-col-span-12 md:tw-col-span-12">
                            <FormField v-model="sheet.sheet_name" :label="$t('fields.sheet_name')" type="text" :error="errors.sheet_name &&
                                $t('errors.' + errors.sheet_name, {
                                    field: $t('fields.sheet_name'),
                                })
                                " />
                        </div>

                        <div v-if="false" class="tw-col-span-12 md:tw-col-span-12">
                            <FormField v-model="sheet.is_active" :label="$t('fields.is_active')" type="text" :error="errors.is_active &&
                                $t('errors.' + errors.is_active, {
                                    field: $t('fields.is_active'),
                                })
                                " />
                        </div>
                    </div>
                </div>

                <div
                    class="tw-p-4 tw-text-lg tw-border-t tw-justify-end tw-border-solid tw-font-medium tw-flex dark:tw-text-gray-700 tw-text-gray-500 tw-items-center tw-gap-4 dark:tw-bg-gray-100 tw-bg-gray-50">
                    <a-button :disabled="loading" @click="onCancel" class="tw-min-w-[120px]"
                        intent="simple">{{
                            $t("buttons.cancel") }}</a-button>
                    <a-button :disabled="loading" :loading="loading" @click="onCreate"
                        class="tw-min-w-[120px]">{{
                            $t("buttons.create") }}</a-button>
                </div>
            </div>
        </a-popup>
    </div>
</template>

<script setup>
import { ref } from "vue";
import FormField from "@/components/form/FormField";
import { create } from "@/api/sheets";
import useAlert from "@/composables/useAlert";
import { useI18n } from "vue-i18n";

const i18n = useI18n()

const visible = ref(false);
const loading = ref(false);
const sheet = ref({});
const errors = ref({});

const onCancel = () => {
    sheet.value = {};
    visible.value = false;
};

const onCreate = async () => {
    loading.value = true;
    return await create(sheet.value)
        .then(
            r => {
                if (r.data.code == 'SUCCESS') {
                    console.log(r.data.data);
                    useAlert(i18n.t('messages.created-successfully', { field: i18n.t('fields.sheet') }))
                    onCancel();
                }
            },
            error => {
                if (error.response.status == 422) {
                    for (let e in error.response.data.errors) {
                        errors.value[e] = error.response.data.errors[e][0];
                    }
                }
            }
        )
        .finally(
            () => loading.value = false
        )
};
</script>

<style></style>
