<template>
  <v-app-bar
    dir="rtl"
    class="tw-top-0 !tw-fixed tw-overflow-visible !tw-border-b"
    elevation="0"
  >
    <div :dir="$root.$i18n.locale == 'ar' ? 'rtl' : 'ltr'" class="tw-flex tw-justify-between tw-w-full tw-px-3">
      <div class="tw-flex tw-items-center tw-gap-5">
        <button
          @click="isActive = !isActive"
          class="active:tw-shadow-none active:tw-translate-y-0.5 active:tw-border-b tw-p-1.5 tw-border tw-border-b-2 tw-border-solid tw-rounded-lg hover:tw-bg-neutral-50/10 hover:tw-shadoxw-md hover:tw-shadow-neutral-200 tw-duration-200"
        >
          <icon
            class="tw-text-2xl tw-text-neutral-600 tw-duration-200"
            icon="solar:hamburger-menu-broken"
          />
        </button>

        <GlobalSearch />
      </div>

      <div class="tw-flex tw-items-center tw-gap-2">

        <BaseButtonHeader v-if="false" />
        
        <LanguageSwitcher />

        <UserNotification />

        <UserMenu />
      </div>
    </div>
  </v-app-bar>
</template>

<script setup>
import LanguageSwitcher from "./header/LanguageSwitcher";
import BaseButtonHeader from "./header/BaseButtonHeader";
import UserNotification from "./header/UserNotification";
import GlobalSearch from "./header/GlobalSearch";
import UserMenu from "./header/UserMenu";
import { defineProps, defineEmits, computed } from "vue";

const emit = defineEmits(["update:drawer"]);
const props = defineProps({
  drawer: {
    required: true,
  },
});

const isActive = computed({
  get: () => props.drawer,
  set: (v) => emit("update:drawer", v),
});
</script>

<style>
</style>