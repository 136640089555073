<template>
  <div>

  </div>
</template>

<script setup>

import { useAuthStore } from "@/stores/auth";
import router from "@/router";

const authStore = useAuthStore();

switch (authStore.user.role) {
  case 'admin':
    router.push({name: 'pages.dashboard.admin'});
  break;
  case 'agent':
    router.push({name: 'pages.dashboard.agent'});
  break;

  default:
    break;
}
</script>
