import { saveInLocalStorage } from '@/utils/local-storage';
import { defineStore } from 'pinia';

const initialState = {
    roles: [],
    fetched: false,
}

export const useRoleStore = defineStore('roleStore', {
    state: () => initialState,

    getters: {},

    actions: {
        setRoles(value) {
            this.roles = value;
            saveInLocalStorage('users', value);
        },

        setFetched(value) {
            this.fetched = value;
        }

    }
});