import ProductListView from "@/views/admin/products/ProductListView";
import ProductEditView from "@/views/admin/products/ProductEditView";
import ProductCreateView from "@/views/admin/products/ProductCreateView";
import ProductDetailView from "@/views/admin/products/ProductDetailView";

export default [
  {
    name: "pages.admin.products",
    path: "products",
    component: ProductListView
  },
  {
    name: "pages.admin.products.create",
    path: "products/create",
    component: ProductCreateView
  },
  {
    name: "pages.admin.products.show",
    path: "products/:id",
    component: ProductDetailView
  },
  {
    name: "pages.admin.products.edit",
    path: "products/:id/edit",
    component: ProductEditView
  },
  
];
