<template>
  <div>
    <div class="tw-rounded tw-overflow-hidden tw-border tw-border-solid tw-border-gray-100">
    <table class="tw-min-w-full tw-leading-normal">
      <thead class="">
        <tr>
          <th
            class="tw-px-5 tw-w-full last:tw-border-e-0 tw-border-e  tw-py-3 tw-border-b-2 tw-borderx tw-border-gray-100 tw-bg-gray-50 tw-text-start tw-text-xs tw-font-semibold tw-text-gray-600 tw-uppercase "
          >
            {{ $t("fields.name") }}
          </th>
          <th
            class="tw-px-5 tw-w-[100px] last:tw-border-e-0 tw-border-e tw-py-3 tw-border-b-2 tw-borderx tw-border-gray-100 tw-bg-gray-50 tw-text-start tw-text-xs tw-font-semibold tw-text-gray-600 tw-uppercase "
          >
          {{ $t("fields.quantity") }}
          </th>
          <th
            class="tw-px-5 tw-w-[100px] last:tw-border-e-0 tw-border-e tw-py-3 tw-border-b-2 tw-borderx tw-border-gray-100 tw-bg-gray-50 tw-text-start tw-text-xs tw-font-semibold tw-text-gray-600 tw-uppercase "
          >
          {{ $t("fields.actions") }}
          </th>
        </tr>
      </thead>
  
      <tbody>
        <tr v-for="v in variants" :key="v">
          <td class="tw-px-5 tw-py-1">{{ v.variant_name }}</td>
          <td class="tw-px-5 tw-py-1">{{ v.quantity }}</td>
          <td class="tw-px-5 tw-py-1">
            <div class="tw-flex tw-items-center tw-gap-2">
                <a-button @click="handleDelete(v)" icon="solar:trash-bin-trash-bold" intent="danger" class="tw-scale-75"></a-button>
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>

  </div>
</template>

<script setup>
import { defineProps, toRef, defineEmits } from 'vue';

const props = defineProps(['variants']);
const emit = defineEmits(['delete']);
const variants = toRef(props, 'variants');


const handleDelete = v => {
    emit('delete', v);
}
</script>

<style></style>
