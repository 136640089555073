import { getFromLocalStorage } from "@/utils/local-storage";
import csrf from "../csrf";

let abortController = new AbortController();

export const abort = (message = 'Session Expired') => {
    abortController.abort(message);
    abortController = new AbortController();
}


export const request = async config => {

    const token = getFromLocalStorage('token');

    await csrf.getCookie();
    
    config.headers.Authorization = `Bearer ${token}`;
    config.signal = abortController.signal;

    return config;
}