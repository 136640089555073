<template>
    <div class="tw-w-full tw-text-center tw-flex tw-justify-end tw-gap-2 tw-px-2">
        <table-sync-action />
        <a-action-button disabled icon="solar:pen-bold-duotone" intent="warning"></a-action-button>
        
        <a-action-button @click="visible.delete = true" icon="solar:trash-bin-2-bold-duotone" intent="danger"></a-action-button>
        <delete-modal :visible="visible.delete" :loading="loading.delete" @confirm="onDeleteConfirmed" @cancel="visible.delete = false" />
    </div>
</template>

<script setup>
import { destroy } from "@/api/sheets";
import TableSyncAction from "./actions/TableSyncAction";
import DeleteModal from "@/components/modals/DeleteModal.vue";
import { ref, defineProps, inject } from 'vue';
import useAlert from "@/composables/useAlert";
import { useI18n } from "vue-i18n";

const i18n = useI18n()
const visible = ref({ delete: false });
const loading = ref({ delete: false });
const props = defineProps({ item: { required: true } });
const onDeleted = inject('onDeleted')

const onDeleteConfirmed = async () => {
    loading.value.delete = true;

    await destroy(props.item.id)
    .then(
        r => {
            if(r.data.code == 'SUCCESS') {
                useAlert(i18n.t('messages.deleted-successfully', { field: i18n.t('fields.sheet') }))
                onDeleted(props.item.id)
                visible.value.delete = false;
            }
        },
        e => {
            console.log(e)
        }
    )
    loading.value.delete = false;

}
</script>

<style>

</style>