import {
    server
} from "@/api";


export const create = async (product) => {
    return await server().post('api/products', product)
}

export const all = async (params) => {
    return await server().get('api/products/all', { params });
}

export const paginate = async (params) => {
    return await server().get('api/products', { params });
}

export const update = async (id, product) => {
    return await server().post(`api/products/${id}`, product);
}

export default {
    create,
    all,
    update,
    paginate
}