
import ConfirmOrdersView from "@/views/agent/confirm/ConfirmOrdersView";
import MyOrdersView from "@/views/agent/orders/MyOrdersView";

export default [
    {
        name: "pages.agent.confirm-orders",
        path: "confirm-orders",
        component: ConfirmOrdersView,
        meta: {
          middleware: ["auth"],
        },
      },
    {
        name: "pages.agent.my-orders",
        path: "my-orders",
        component: MyOrdersView,
        meta: {
          middleware: ["auth"],
        },
      },
]