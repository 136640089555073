<template>
  <div class="tw-min-h-[2000px]">
    <div class="tw-p-2 tw-w-full tw-h-full">
      <div class="tw-grid tw-grid-cols-12 tw-gap-3 tw-mb-5">
        <div class="tw-col-span-12 md:tw-col-span-6 lg:tw-col-span-3 tw-min-h-[120px] tw-rounded-md tw-bg-white"></div>
        <div class="tw-col-span-12 md:tw-col-span-6 lg:tw-col-span-3 tw-min-h-[120px] tw-rounded-md tw-bg-white"></div>
        <div class="tw-col-span-12 md:tw-col-span-6 lg:tw-col-span-3 tw-min-h-[120px] tw-rounded-md tw-bg-white"></div>
        <div class="tw-col-span-12 md:tw-col-span-6 lg:tw-col-span-3 tw-min-h-[120px] tw-rounded-md tw-bg-white"></div>
      </div>
      <div class="tw-w-full tw-h-full tw-bg-white tw-rounded-md tw-border tw-p-2">

      </div>
    </div>
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>