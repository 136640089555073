<template>
  <div>
    <FormVueSelect :searchable="false" v-model="order.agent_status" max-height="200" :options="confirmations" option-value="value"
      :label="$t('fields.agent_status')" :error="errors.agent_status &&
        $t('errors.' + errors.agent_status, {
          field: $t('fields.agent_status'),
        })
        ">
      <template v-slot:option="{ option }">
        <p :class="[option.color]" class="tw-p-2 tw-rounded tw-text-xs tw-px-4">{{
          $t(option.name) }}</p>
      </template>
    </FormVueSelect>
  </div>
</template>

<script setup>
import FormVueSelect from "@/components/form/FormVueSelect";
import { order_confirmations } from '@/config/status';
import { defineProps, defineEmits, computed, toRef } from 'vue';
import { useI18n } from "vue-i18n";

const i18n = useI18n();

const confirmations = computed(() => order_confirmations.map(c => ({ ...c, label: i18n.t(c.name) })))
const props = defineProps(['order', 'errors'])
const emit = defineEmits(['update:order'])
const errors = toRef(props, 'errors')

const order = computed({
  get() {
    return props.order
  },
  set(value) {
    emit('update:order', value)
  } 
})
</script>

<style></style>