import {
    server
} from "@/api";


export const all = async (params) => {
    return await server().get('api/roles', { params });
}


export default {
    all
}